import React from 'react';
import { AudioOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
function Search() {
    const { Search } = Input;
    const suffix = (
      <AudioOutlined
        style={{
          fontSize: 16,
          color: '#1677ff',
        }}
      />
    );
    const onSearch = (value, _e, info) => console.log(info?.source, value);
  return (
    <div style={{
        display:'flex',
        flexDirection:'column',
        gap:'10px'
    }}>
         <Search className='auf-search' placeholder="input search text" onSearch={onSearch} style={{ width: 200 }} />
    <Search className='auf-search' placeholder="input search text" allowClear onSearch={onSearch} style={{ width: 200 }} />
    <Search
    className='auf-search'
      addonBefore="https://"
      placeholder="input search text"
      allowClear
      onSearch={onSearch}
      style={{ width: 304 }}
    />
    <Search className='auf-search' placeholder="input search text" onSearch={onSearch} enterButton />
    <Search
    className='auf-search'
      placeholder="input search text"
      allowClear
      enterButton="Search"
      size="large"
      onSearch={onSearch}
    />
    <Search
    className='auf-search'
      placeholder="input search text"
      enterButton="Search"
      size="large"
      suffix={suffix}
      onSearch={onSearch}
    />
    </div>
  )
}

export default Search