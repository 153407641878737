import { Button } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import React from 'react';

function BackToTop() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Button onClick={scrollToTop} className='auf-button'>
      <ArrowUpOutlined />
    </Button>
  );
}

export default BackToTop;
