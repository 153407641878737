import { Checkbox } from 'antd';
import React, { useState } from 'react'

function CheckAll() {
    const CheckboxGroup = Checkbox.Group;
    const plainOptions = ['Apple', 'Pear', 'Orange'];
    const defaultCheckedList = ['Apple', 'Orange'];
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
  const onChange = (list) => {
    setCheckedList(list);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
  };

  return (
    <div 
    style={{
        display:"flex",
        flexDirection:"column",
        gap:"20px"
    }}
    >
    <Checkbox  className='auf-checkbox'indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
      Check all
    </Checkbox>
    <CheckboxGroup className='auf-checkbox' options={plainOptions} value={checkedList} onChange={onChange} />
  </div>
  )
}

export default CheckAll