import React from 'react'
import CompLayout from '../Components/CompLayout'
import Layout from './../Components/Layout';
import BasicCollapse from '../Components/Elements/Collapse/BasicCollapse';
import Accordion from '../Components/Elements/Collapse/Accordion';
import NestedCollapse from '../Components/Elements/Collapse/NestedCollapse';

function Collapse() {
  const code =[
    {
      key: 1,
      comp: "basic collapse",
      code: [
        {
          tab: 'js',
          code: `import React from 'react'
          import { Collapse } from 'antd';
          
          function App() {
              const text = "
            A dog is a type of domesticated animal.
            Known for its loyalty and faithfulness,
            it can be found as a welcome guest in many households across the world.";
          const items = [
              {
                key: '1',
                label: 'This is panel header 1',
                children: <p>{text}</p>,
              },
              {
                key: '2',
                label: 'This is panel header 2',
                children: <p>{text}</p>,
              },
              {
                key: '3',
                label: 'This is panel header 3',
                children: <p>{text}</p>,
              },
            ];
            return (
              <Collapse className='auf-collapse' items={items} defaultActiveKey={['1']} />
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react'
          import { Collapse } from 'antd';
          
          const App: React.FC = () => {
              const text = "
            A dog is a type of domesticated animal.
            Known for its loyalty and faithfulness,
            it can be found as a welcome guest in many households across the world.";
            const items: CollapseProps['items'] = [
              {
                key: '1',
                label: 'This is panel header 1',
                children: <p>{text}</p>,
              },
              {
                key: '2',
                label: 'This is panel header 2',
                children: <p>{text}</p>,
              },
              {
                key: '3',
                label: 'This is panel header 3',
                children: <p>{text}</p>,
              },
            ];
            return (
              <Collapse className='auf-collapse' items={items} defaultActiveKey={['1']} />
            )
          }
          
          export default App
          `
        },
        {
          tab: 'scss',
          code: `.auf-collapse{
            width: 100%;
            background-color: var(--theme-bg-primary);
            .ant-collapse-item{
                border-color: var(--theme-bg-reverse);
            }
            .ant-collapse-arrow{
        color: var(--theme-bg-reverse) !important;
            }
            .ant-collapse-header-text{
                color: var(--theme-bg-reverse);
            }
            .ant-collapse-content{
                border-color: var( --theme-box-bg) !important;
                background-color: var(--theme-bg-primary);
                .ant-collapse-content-box{
                    p{
                        color: var(--theme-bg-reverse);
                    }
                }
            }
        }
         `
        },
      ]
    },
    {
      key: 2,
      comp: "accordion",
      code: [
        {
          tab: 'js',
          code: `import React from 'react'
          import { Collapse } from 'antd';
          const text = "
          A dog is a type of domesticated animal.
          Known for its loyalty and faithfulness,
          it can be found as a welcome guest in many households across the world.";
          const items = [
            {
              key: '1',
              label: 'This is panel header 1',
              children: <p>{text}</p>,
            },
            {
              key: '2',
              label: 'This is panel header 2',
              children: <p>{text}</p>,
            },
            {
              key: '3',
              label: 'This is panel header 3',
              children: <p>{text}</p>,
            },
          ];
          function App() {
            return (
             <Collapse defaultActiveKey={'2'} className='auf-collapse' accordion items={items} />
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react'
          import { Collapse } from 'antd';
          const text = "
          A dog is a type of domesticated animal.
          Known for its loyalty and faithfulness,
          it can be found as a welcome guest in many households across the world.";
          const items: CollapseProps['items'] = [
            {
              key: '1',
              label: 'This is panel header 1',
              children: <p>{text}</p>,
            },
            {
              key: '2',
              label: 'This is panel header 2',
              children: <p>{text}</p>,
            },
            {
              key: '3',
              label: 'This is panel header 3',
              children: <p>{text}</p>,
            },
          ];
          const App: React.FC = () => {
            return (
             <Collapse defaultActiveKey={'2'} className='auf-collapse' accordion items={items} />
            )
          }
          
          export default App
          `
        },
        {
          tab: 'scss',
          code: `.auf-collapse{
            width: 100%;
            background-color: var(--theme-bg-primary);
            .ant-collapse-item{
                border-color: var(--theme-bg-reverse);
            }
            .ant-collapse-arrow{
        color: var(--theme-bg-reverse) !important;
            }
            .ant-collapse-header-text{
                color: var(--theme-bg-reverse);
            }
            .ant-collapse-content{
                border-color: var( --theme-box-bg) !important;
                background-color: var(--theme-bg-primary);
                .ant-collapse-content-box{
                    p{
                        color: var(--theme-bg-reverse);
                    }
                }
            }
        }
         `
        },
      ]
    },
    {
      key: 3,
      comp: "nested collapse",
      code: [
        {
          tab: 'js',
          code: `import React from 'react'
          import { Collapse } from 'antd';
          const text = "
          A dog is a type of domesticated animal.
          Known for its loyalty and faithfulness,
          it can be found as a welcome guest in many households across the world.";
          const itemsNest = [
            {
              key: '1',
              label: 'This is panel nest panel',
              children: <p>{text}</p>,
            },
          ];
          const items = [
            {
              key: '1',
              label: 'This is panel header 1',
              children: <Collapse defaultActiveKey="1" items={itemsNest} />,
            },
            {
              key: '2',
              label: 'This is panel header 2',
              children: <p>{text}</p>,
            },
            {
              key: '3',
              label: 'This is panel header 3',
              children: <p>{text}</p>,
            },
          ];
          function App() {
            return (
              <Collapse className='auf-collapse' items={items} />
            )
          }
          
          export default App
         `
        },
        {
          tab: 'ts',
          code: `import React from 'react'
          import { Collapse } from 'antd';
          const text = "
          A dog is a type of domesticated animal.
          Known for its loyalty and faithfulness,
          it can be found as a welcome guest in many households across the world.";
          const itemsNest: CollapseProps['items'] = [
            {
              key: '1',
              label: 'This is panel nest panel',
              children: <p>{text}</p>,
            },
          ];
          const items = [
            {
              key: '1',
              label: 'This is panel header 1',
              children: <Collapse defaultActiveKey="1" items={itemsNest} />,
            },
            {
              key: '2',
              label: 'This is panel header 2',
              children: <p>{text}</p>,
            },
            {
              key: '3',
              label: 'This is panel header 3',
              children: <p>{text}</p>,
            },
          ];
          const App: React.FC = () =>{
            return (
              <Collapse className='auf-collapse' items={items} />
            )
          }
          
          export default App
         `
        },
        {
          tab: 'scss',
          code: `.auf-collapse{
            width: 100%;
            background-color: var(--theme-bg-primary);
            .ant-collapse-item{
                border-color: var(--theme-bg-reverse);
            }
            .ant-collapse-arrow{
        color: var(--theme-bg-reverse) !important;
            }
            .ant-collapse-header-text{
                color: var(--theme-bg-reverse);
            }
            .ant-collapse-content{
                border-color: var( --theme-box-bg) !important;
                background-color: var(--theme-bg-primary);
                .ant-collapse-content-box{
                    p{
                        color: var(--theme-bg-reverse);
                    }
                }
            }
        }
         `
        },
      ]
    },
  ]
  return (
    <Layout title='collapse'>
    <CompLayout title={"Basic Collapse"} code={code[0]}>
<BasicCollapse />
  </CompLayout>
    <CompLayout title={"Accordion"} code={code[1]}>
<Accordion />
  </CompLayout>
    <CompLayout title={"Nested collapse"} code={code[2]}>
<NestedCollapse />
  </CompLayout>
  </Layout>
  )
}

export default Collapse