import { Card } from 'antd'
import React from 'react'

function ImageCard() {
    const { Meta } = Card;
  return (
    <Card
     className='auf-card'
    hoverable
    style={{
      width: 240,
    }}
    cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
  >
    <Meta title="Europe Street beat" description="www.instagram.com" />
  </Card>
  )
}

export default ImageCard