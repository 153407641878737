import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import BasicRadio from '../Components/Elements/Radio/BasicRadio'
import RadioGroup from '../Components/Elements/Radio/RadioGroup'
import RadioStyles from '../Components/Elements/Radio/RadioStyles'
import RadioSize from '../Components/Elements/Radio/RadioSize'

function RadioButton() {
  const code =[
    {
      key: 1,
      comp: "basic radio",
      code: [
        {
          tab: 'js',
          code: `import { Radio } from 'antd'
          import React from 'react'
          
          function App() {
            return (
              <Radio className='auf-radio'>Radio</Radio>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import { Radio } from 'antd'
          import React from 'react'
          
          const App: React.FC = () =>{
            return (
              <Radio className='auf-radio'>Radio</Radio>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'scss',
          code: `.auf-radio{
            height: fit-content;
            &.ant-radio-wrapper{
                span{
                    color: var(--theme-bg-reverse);
                }
                .ant-radio-inner{
                    background-color: var(--theme-bg-primary);
                    border-color: var(--theme-bg-reverse);
                }
                .ant-radio-checked{
                    .ant-radio-inner{
                        background-color: var(--theme-nav-link-primary);
                        border-color: var(--theme-nav-link-primary);
        
                        &::after{
                            background-color: var(--theme-bg-primary);
                        }
                    }
                    &::after{
                        border-color: transparent !important;
                    }
                }
        
                &:hover{
                    .ant-radio-inner{
                        border-color: var(--theme-nav-link-primary);
                    }
        
                }
            }
        &.ant-radio-group-outline{
        
            .ant-radio-button-wrapper{
                background-color: var(--theme-bg-primary);
                color: var(--theme-bg-reverse);
                &.ant-radio-button-wrapper-checked{
                    border-color: var(--theme-nav-link-primary);
            &::before{
            background-color: var(--theme-nav-link-primary) ;
        }
                    span{
            color: var(--theme-nav-link-primary);
                    }
                }
                &:hover{
                    span{
                        color: var(--theme-nav-link-primary);
                    }
                }
            }
        }
        &.ant-radio-group-solid{
            .ant-radio-button-wrapper{
                background-color: var(--theme-bg-primary);
                color: var(--theme-bg-reverse);
                &.ant-radio-button-wrapper-checked{
                    background-color: var(--theme-nav-link-primary);
                    border-color: var(--theme-nav-link-primary);
                    color: var(--theme-bg-primary) !important;
        
                    &::before{
                        background-color: var(--theme-nav-link-primary);
                    }
                }
                &:hover{
                        color: var(--theme-nav-link-primary);
                }
            }
        }
        .ant-radio-button-wrapper-disabled{
            background-color: $gray !important;
        }
        }
         `
        },
      ]
    },
    {
      key: 2,
      comp: "radio group",
      code: [
        {
          tab: 'js',
          code: `import { Radio } from 'antd'
          import React, { useState } from 'react'
          
          function App() {
              const [value, setValue] = useState(1);
              const onChange = (e) => {
                console.log('radio checked', e.target.value);
                setValue(e.target.value);
              };
            return (
              <Radio.Group value={value} onChange={onChange}>
              <Radio className='auf-radio' value={1}>A</Radio>
              <Radio className='auf-radio' value={2}>B</Radio>
              <Radio className='auf-radio' value={3}>C</Radio>
              <Radio className='auf-radio' value={4}>D</Radio>
            </Radio.Group>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import { Radio } from 'antd'
          import React, { useState } from 'react'
          
          const App: React.FC = () =>{
              const [value, setValue] = useState(1);
              const onChange = (e: RadioChangeEvent) => {
                console.log('radio checked', e.target.value);
                setValue(e.target.value);
              };
            return (
              <Radio.Group value={value} onChange={onChange}>
              <Radio className='auf-radio' value={1}>A</Radio>
              <Radio className='auf-radio' value={2}>B</Radio>
              <Radio className='auf-radio' value={3}>C</Radio>
              <Radio className='auf-radio' value={4}>D</Radio>
            </Radio.Group>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'scss',
          code: `.auf-radio{
            height: fit-content;
            &.ant-radio-wrapper{
                span{
                    color: var(--theme-bg-reverse);
                }
                .ant-radio-inner{
                    background-color: var(--theme-bg-primary);
                    border-color: var(--theme-bg-reverse);
                }
                .ant-radio-checked{
                    .ant-radio-inner{
                        background-color: var(--theme-nav-link-primary);
                        border-color: var(--theme-nav-link-primary);
        
                        &::after{
                            background-color: var(--theme-bg-primary);
                        }
                    }
                    &::after{
                        border-color: transparent !important;
                    }
                }
        
                &:hover{
                    .ant-radio-inner{
                        border-color: var(--theme-nav-link-primary);
                    }
        
                }
            }
        &.ant-radio-group-outline{
        
            .ant-radio-button-wrapper{
                background-color: var(--theme-bg-primary);
                color: var(--theme-bg-reverse);
                &.ant-radio-button-wrapper-checked{
                    border-color: var(--theme-nav-link-primary);
            &::before{
            background-color: var(--theme-nav-link-primary) ;
        }
                    span{
            color: var(--theme-nav-link-primary);
                    }
                }
                &:hover{
                    span{
                        color: var(--theme-nav-link-primary);
                    }
                }
            }
        }
        &.ant-radio-group-solid{
            .ant-radio-button-wrapper{
                background-color: var(--theme-bg-primary);
                color: var(--theme-bg-reverse);
                &.ant-radio-button-wrapper-checked{
                    background-color: var(--theme-nav-link-primary);
                    border-color: var(--theme-nav-link-primary);
                    color: var(--theme-bg-primary) !important;
        
                    &::before{
                        background-color: var(--theme-nav-link-primary);
                    }
                }
                &:hover{
                        color: var(--theme-nav-link-primary);
                }
            }
        }
        .ant-radio-button-wrapper-disabled{
            background-color: $gray !important;
        }
        }
         `
        },
      ]
    },
    {
      key: 3,
      comp: "radio styles",
      code: [
        {
          tab: 'js',
          code: `import { Radio } from 'antd';
          import React, { useState } from 'react'
          const plainOptions = ['Apple', 'Pear', 'Orange'];
          const options = [
            {
              label: 'Apple',
              value: 'Apple',
            },
            {
              label: 'Pear',
              value: 'Pear',
            },
            {
              label: 'Orange',
              value: 'Orange',
              title: 'Orange',
            },
          ];
          const optionsWithDisabled = [
            {
              label: 'Apple',
              value: 'Apple',
            },
            {
              label: 'Pear',
              value: 'Pear',
            },
            {
              label: 'Orange',
              value: 'Orange',
              disabled: true,
            },
          ];
          function App() {
              const [value1, setValue1] = useState('Apple');
              const [value2, setValue2] = useState('Apple');
              const onChange1 = ({ target: { value } }: RadioChangeEvent) => { => {
                  console.log('radio1 checked', value);
                  setValue1(value);
                };
                const onChange2 = ({ target: { value } }: RadioChangeEvent) => { => {
                  console.log('radio2 checked', value);
                  setValue2(value);
                };
            return (
              <div>
          <Radio.Group className='auf-radio' options={options} onChange={onChange1} 
          value={value1} optionType="button" />
          <br /><br />
                <Radio.Group
                className='auf-radio'
                  options={optionsWithDisabled}
                  onChange={onChange2}
                  value={value2}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import { Radio } from 'antd';
          import React, { useState } from 'react'
          const plainOptions = ['Apple', 'Pear', 'Orange'];
          const options = [
            { label: 'Apple', value: 'Apple' },
            { label: 'Pear', value: 'Pear' },
            { label: 'Orange', value: 'Orange', title: 'Orange' },
          ];
          const optionsWithDisabled = [
            { label: 'Apple', value: 'Apple' },
            { label: 'Pear', value: 'Pear' },
            { label: 'Orange', value: 'Orange', disabled: true },
          ];
          function App() {
              const [value1, setValue1] = useState('Apple');
              const [value2, setValue2] = useState('Apple');
              const onChange1 = ({ target: { value } }) => {
                  console.log('radio1 checked', value);
                  setValue1(value);
                };
                const onChange2 = ({ target: { value } }) => {
                  console.log('radio2 checked', value);
                  setValue2(value);
                };
            return (
              <div>
          <Radio.Group className='auf-radio' options={options} onChange={onChange1} 
          value={value1} optionType="button" />
          <br /><br />
                <Radio.Group
                className='auf-radio'
                  options={optionsWithDisabled}
                  onChange={onChange2}
                  value={value2}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'scss',
          code: `.auf-radio{
            height: fit-content;
            &.ant-radio-wrapper{
                span{
                    color: var(--theme-bg-reverse);
                }
                .ant-radio-inner{
                    background-color: var(--theme-bg-primary);
                    border-color: var(--theme-bg-reverse);
                }
                .ant-radio-checked{
                    .ant-radio-inner{
                        background-color: var(--theme-nav-link-primary);
                        border-color: var(--theme-nav-link-primary);
        
                        &::after{
                            background-color: var(--theme-bg-primary);
                        }
                    }
                    &::after{
                        border-color: transparent !important;
                    }
                }
        
                &:hover{
                    .ant-radio-inner{
                        border-color: var(--theme-nav-link-primary);
                    }
        
                }
            }
        &.ant-radio-group-outline{
        
            .ant-radio-button-wrapper{
                background-color: var(--theme-bg-primary);
                color: var(--theme-bg-reverse);
                &.ant-radio-button-wrapper-checked{
                    border-color: var(--theme-nav-link-primary);
            &::before{
            background-color: var(--theme-nav-link-primary) ;
        }
                    span{
            color: var(--theme-nav-link-primary);
                    }
                }
                &:hover{
                    span{
                        color: var(--theme-nav-link-primary);
                    }
                }
            }
        }
        &.ant-radio-group-solid{
            .ant-radio-button-wrapper{
                background-color: var(--theme-bg-primary);
                color: var(--theme-bg-reverse);
                &.ant-radio-button-wrapper-checked{
                    background-color: var(--theme-nav-link-primary);
                    border-color: var(--theme-nav-link-primary);
                    color: var(--theme-bg-primary) !important;
        
                    &::before{
                        background-color: var(--theme-nav-link-primary);
                    }
                }
                &:hover{
                        color: var(--theme-nav-link-primary);
                }
            }
        }
        .ant-radio-button-wrapper-disabled{
            background-color: $gray !important;
        }
        }
         `
        },
      ]
    },
    {
      key: 4,
      comp: "radio styles",
      code: [
        {
          tab: 'js',
          code: `import { Radio } from 'antd'
          import React from 'react'
          
          function App() {
            return (
              <div >
           <Radio.Group defaultValue="a" size="large" className='auf-radio'>
                <Radio.Button  value="a">Hangzhou</Radio.Button>
                <Radio.Button  value="b">Shanghai</Radio.Button>
                <Radio.Button  value="c">Beijing</Radio.Button>
                <Radio.Button  value="d">Chengdu</Radio.Button>
              </Radio.Group>
              <br />
              <br />
              <Radio.Group defaultValue="a" className='auf-radio'>
                <Radio.Button value="a">Hangzhou</Radio.Button>
                <Radio.Button value="b">Shanghai</Radio.Button>
                <Radio.Button value="c">Beijing</Radio.Button>
                <Radio.Button value="d">Chengdu</Radio.Button>
              </Radio.Group>
              <br />
              <br />
              <Radio.Group defaultValue="a" size="small" className='auf-radio'>
                <Radio.Button value="a">Hangzhou</Radio.Button>
                <Radio.Button value="b">Shanghai</Radio.Button>
                <Radio.Button value="c">Beijing</Radio.Button>
                <Radio.Button value="d">Chengdu</Radio.Button>
              </Radio.Group>
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Flex, Radio } from 'antd';
          
          const App: React.FC = () => (
            <Flex vertical gap="middle">
              <Radio.Group defaultValue="a" size="large">
                <Radio.Button value="a">Hangzhou</Radio.Button>
                <Radio.Button value="b">Shanghai</Radio.Button>
                <Radio.Button value="c">Beijing</Radio.Button>
                <Radio.Button value="d">Chengdu</Radio.Button>
              </Radio.Group>
              <Radio.Group defaultValue="a">
                <Radio.Button value="a">Hangzhou</Radio.Button>
                <Radio.Button value="b">Shanghai</Radio.Button>
                <Radio.Button value="c">Beijing</Radio.Button>
                <Radio.Button value="d">Chengdu</Radio.Button>
              </Radio.Group>
              <Radio.Group defaultValue="a" size="small">
                <Radio.Button value="a">Hangzhou</Radio.Button>
                <Radio.Button value="b">Shanghai</Radio.Button>
                <Radio.Button value="c">Beijing</Radio.Button>
                <Radio.Button value="d">Chengdu</Radio.Button>
              </Radio.Group>
            </Flex>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-radio{
            height: fit-content;
            &.ant-radio-wrapper{
                span{
                    color: var(--theme-bg-reverse);
                }
                .ant-radio-inner{
                    background-color: var(--theme-bg-primary);
                    border-color: var(--theme-bg-reverse);
                }
                .ant-radio-checked{
                    .ant-radio-inner{
                        background-color: var(--theme-nav-link-primary);
                        border-color: var(--theme-nav-link-primary);
        
                        &::after{
                            background-color: var(--theme-bg-primary);
                        }
                    }
                    &::after{
                        border-color: transparent !important;
                    }
                }
        
                &:hover{
                    .ant-radio-inner{
                        border-color: var(--theme-nav-link-primary);
                    }
        
                }
            }
        &.ant-radio-group-outline{
        
            .ant-radio-button-wrapper{
                background-color: var(--theme-bg-primary);
                color: var(--theme-bg-reverse);
                &.ant-radio-button-wrapper-checked{
                    border-color: var(--theme-nav-link-primary);
            &::before{
            background-color: var(--theme-nav-link-primary) ;
        }
                    span{
            color: var(--theme-nav-link-primary);
                    }
                }
                &:hover{
                    span{
                        color: var(--theme-nav-link-primary);
                    }
                }
            }
        }
        &.ant-radio-group-solid{
            .ant-radio-button-wrapper{
                background-color: var(--theme-bg-primary);
                color: var(--theme-bg-reverse);
                &.ant-radio-button-wrapper-checked{
                    background-color: var(--theme-nav-link-primary);
                    border-color: var(--theme-nav-link-primary);
                    color: var(--theme-bg-primary) !important;
        
                    &::before{
                        background-color: var(--theme-nav-link-primary);
                    }
                }
                &:hover{
                        color: var(--theme-nav-link-primary);
                }
            }
        }
        .ant-radio-button-wrapper-disabled{
            background-color: $gray !important;
        }
        }
         `
        },
      ]
    },
  ]
  return (
    <Layout title='radio'>
    <CompLayout title={"Basic radio button"} code={code[0]}>
<BasicRadio />
  </CompLayout>
    <CompLayout title={"radio group"} code={code[1]}>
<RadioGroup />
  </CompLayout>
    <CompLayout title={"radio styles"} code={code[2]}>
<RadioStyles />
  </CompLayout>
    <CompLayout title={"radio sizes"} code={code[3]}>
<RadioSize />
  </CompLayout>
  </Layout>
  )
}

export default RadioButton