import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import BasicTooltip from '../Components/Elements/Tooltip/BasicTooltip'
import PlacementTooltip from '../Components/Elements/Tooltip/PlacementTooltip'
import ColorTooltip from '../Components/Elements/Tooltip/ColorTooltip'
import DisabledTooltip from '../Components/Elements/Tooltip/DisabledTooltip'

function Tooltip() {
  const code = [
    {
      key: 1,
      comp: "basic tooltip",
      code: [
        {
          tab: 'js',
          code: `import { Tooltip } from 'antd'
          import React from 'react'
          
          function App() {
            return (
              <Tooltip overlayClassName="auf-tooltip-overlay" className='auf-tooltip' title="This is a text">
              <span>Tooltip will show on mouse enter.</span>
            </Tooltip>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Tooltip } from 'antd';
          
          const App: React.FC = () => (
            <Tooltip overlayClassName="auf-tooltip-overlay" className='auf-tooltip' title="This is a text">
            <span>Tooltip will show on mouse enter.</span>
          </Tooltip>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-tooltip{
            color: var(--theme-bg-reverse);
         }
         
         .auf-tooltip-overlay{
             .ant-tooltip-content{
                 .ant-tooltip-inner{
         background-color: var(--theme-bg-reverse);
                     color: var(--theme-bg-primary);
                 }
             }
             .ant-tooltip-arrow{
                 --antd-arrow-background-color:var(--theme-bg-reverse);
             }
         }
         `
        },
      ]
    },
    {
      key: 2,
      comp: "placement tooltip",
      code: [
        {
          tab: 'js',
          code: `import { Button, ConfigProvider, Tooltip } from 'antd'
          import React from 'react'
          
          function App() {
              const text = "prompt text"
              const buttonWidth = 80;
            return (
              <ConfigProvider
              button={{
                style: {
                  width: buttonWidth,
                  margin: 4,
                },
              }}
            >
              <div className="demo">
                <div
                  style={{
                    marginInlineStart: buttonWidth,
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Tooltip overlayClassName="auf-tooltip-overlay" placement="topLeft" title={text}>
                    <Button className='auf-button'>TL</Button>
                  </Tooltip>
                  <Tooltip overlayClassName="auf-tooltip-overlay" placement="top" title={text}>
                    <Button className='auf-button'>Top</Button>
                  </Tooltip>
                  <Tooltip overlayClassName="auf-tooltip-overlay" placement="topRight" title={text}>
                    <Button className='auf-button'>TR</Button>
                  </Tooltip>
                </div>
                <div
                  style={{
                    width: buttonWidth,
                    float: 'inline-start',
                  }}
                >
                  <Tooltip overlayClassName="auf-tooltip-overlay" placement="leftTop" title={text}>
                    <Button className='auf-button'>LT</Button>
                  </Tooltip>
                  <Tooltip overlayClassName="auf-tooltip-overlay" placement="left" title={text}>
                    <Button className='auf-button'>Left</Button>
                  </Tooltip>
                  <Tooltip overlayClassName="auf-tooltip-overlay" placement="leftBottom" title={text}>
                    <Button className='auf-button'>LB</Button>
                  </Tooltip>
                </div>
                <div
                  style={{
                    width: buttonWidth,
                    marginInlineStart: buttonWidth * 4 + 24,
                  }}
                >
                  <Tooltip overlayClassName="auf-tooltip-overlay" placement="rightTop" title={text}>
                    <Button className='auf-button'>RT</Button>
                  </Tooltip>
                  <Tooltip overlayClassName="auf-tooltip-overlay" placement="right" title={text}>
                    <Button className='auf-button'>Right</Button>
                  </Tooltip>
                  <Tooltip overlayClassName="auf-tooltip-overlay" placement="rightBottom" title={text}>
                    <Button className='auf-button'>RB</Button>
                  </Tooltip>
                </div>
                <div
                  style={{
                    marginInlineStart: buttonWidth,
                    clear: 'both',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Tooltip overlayClassName="auf-tooltip-overlay" placement="bottomLeft" title={text}>
                    <Button className='auf-button'>BL</Button>
                  </Tooltip>
                  <Tooltip overlayClassName="auf-tooltip-overlay" placement="bottom" title={text}>
                    <Button className='auf-button'>Bottom</Button>
                  </Tooltip>
                  <Tooltip overlayClassName="auf-tooltip-overlay" placement="bottomRight" title={text}>
                    <Button className='auf-button'>BR</Button>
                  </Tooltip>
                </div>
              </div>
            </ConfigProvider>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Button, Tooltip, ConfigProvider } from 'antd';
          
          const text = <span>prompt text</span>;
          
          const buttonWidth = 80;
          
          const App: React.FC = () => (
            <ConfigProvider
            button={{
              style: {
                width: buttonWidth,
                margin: 4,
              },
            }}
          >
            <div className="demo">
              <div
                style={{
                  marginInlineStart: buttonWidth,
                  whiteSpace: 'nowrap',
                }}
              >
                <Tooltip overlayClassName="auf-tooltip-overlay" placement="topLeft" title={text}>
                  <Button className='auf-button'>TL</Button>
                </Tooltip>
                <Tooltip overlayClassName="auf-tooltip-overlay" placement="top" title={text}>
                  <Button className='auf-button'>Top</Button>
                </Tooltip>
                <Tooltip overlayClassName="auf-tooltip-overlay" placement="topRight" title={text}>
                  <Button className='auf-button'>TR</Button>
                </Tooltip>
              </div>
              <div
                style={{
                  width: buttonWidth,
                  float: 'inline-start',
                }}
              >
                <Tooltip overlayClassName="auf-tooltip-overlay" placement="leftTop" title={text}>
                  <Button className='auf-button'>LT</Button>
                </Tooltip>
                <Tooltip overlayClassName="auf-tooltip-overlay" placement="left" title={text}>
                  <Button className='auf-button'>Left</Button>
                </Tooltip>
                <Tooltip overlayClassName="auf-tooltip-overlay" placement="leftBottom" title={text}>
                  <Button className='auf-button'>LB</Button>
                </Tooltip>
              </div>
              <div
                style={{
                  width: buttonWidth,
                  marginInlineStart: buttonWidth * 4 + 24,
                }}
              >
                <Tooltip overlayClassName="auf-tooltip-overlay" placement="rightTop" title={text}>
                  <Button className='auf-button'>RT</Button>
                </Tooltip>
                <Tooltip overlayClassName="auf-tooltip-overlay" placement="right" title={text}>
                  <Button className='auf-button'>Right</Button>
                </Tooltip>
                <Tooltip overlayClassName="auf-tooltip-overlay" placement="rightBottom" title={text}>
                  <Button className='auf-button'>RB</Button>
                </Tooltip>
              </div>
              <div
                style={{
                  marginInlineStart: buttonWidth,
                  clear: 'both',
                  whiteSpace: 'nowrap',
                }}
              >
                <Tooltip overlayClassName="auf-tooltip-overlay" placement="bottomLeft" title={text}>
                  <Button className='auf-button'>BL</Button>
                </Tooltip>
                <Tooltip overlayClassName="auf-tooltip-overlay" placement="bottom" title={text}>
                  <Button className='auf-button'>Bottom</Button>
                </Tooltip>
                <Tooltip overlayClassName="auf-tooltip-overlay" placement="bottomRight" title={text}>
                  <Button className='auf-button'>BR</Button>
                </Tooltip>
              </div>
            </div>
          </ConfigProvider>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-tooltip{
            color: var(--theme-bg-reverse);
         }
         
         .auf-tooltip-overlay{
             .ant-tooltip-content{
                 .ant-tooltip-inner{
         background-color: var(--theme-bg-reverse);
                     color: var(--theme-bg-primary);
                 }
             }
             .ant-tooltip-arrow{
                 --antd-arrow-background-color:var(--theme-bg-reverse);
             }
         }
         `
        },
      ]
    },
    {
      key: 3,
      comp: "color tooltip",
      code: [
        {
          tab: 'js',
          code: `import { Button, Tooltip } from 'antd';
          import React from 'react'
          
          function App() {
              const colors = [
                  'pink',
                  'red',
                  'yellow',
                  'orange',
                  'cyan',
                  'green',
                  'blue',
                  'purple',
                  'geekblue',
                  'magenta',
                  'volcano',
                  'gold',
                  'lime',
                ];
                const customColors = ['#f50', '#2db7f5', '#87d068', '#108ee9'];
            return (
              <div
              style={{
                  display:'flex',
                  flexDirection:"column",
                  gap:"20px",
                  alignItems:"center"
              }}
              >
          <div className=""     style={{
                  display:'flex',
                  gap:"5px"
              }}>
          {colors.map((color) => (
                  <Tooltip  title="prompt text" color={color} key={color}>
                    <Button className='auf-button'>{color}</Button>
                  </Tooltip>
                ))}
          </div>
          <div className=""     style={{
                  display:'flex',
                  gap:"5px"
              }}>
          {customColors.map((color) => (
                  <Tooltip title="prompt text" color={color} key={color}>
                    <Button className='auf-button'>{color}</Button>
                  </Tooltip>
                ))}
          </div>
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import { Button, Tooltip } from 'antd';
          import React from 'react'
          
          const App: React.FC = () => {
            const colors = [
              'pink',
              'red',
              'yellow',
              'orange',
              'cyan',
              'green',
              'blue',
              'purple',
              'geekblue',
              'magenta',
              'volcano',
              'gold',
              'lime',
            ];
            const customColors = ['#f50', '#2db7f5', '#87d068', '#108ee9'];
            return (
              <div
              style={{
                  display:'flex',
                  flexDirection:"column",
                  gap:"20px",
                  alignItems:"center"
              }}
              >
          <div className=""     style={{
                  display:'flex',
                  gap:"5px"
              }}>
          {colors.map((color) => (
                  <Tooltip  title="prompt text" color={color} key={color}>
                    <Button className='auf-button'>{color}</Button>
                  </Tooltip>
                ))}
          </div>
          <div className=""     style={{
                  display:'flex',
                  gap:"5px"
              }}>
          {customColors.map((color) => (
                  <Tooltip title="prompt text" color={color} key={color}>
                    <Button className='auf-button'>{color}</Button>
                  </Tooltip>
                ))}
          </div>
              </div>
            )
          }
          export default App
          `
        },
        {
          tab: 'scss',
          code: `.auf-tooltip{
            color: var(--theme-bg-reverse);
         }
         
         .auf-tooltip-overlay{
             .ant-tooltip-content{
                 .ant-tooltip-inner{
         background-color: var(--theme-bg-reverse);
                     color: var(--theme-bg-primary);
                 }
             }
             .ant-tooltip-arrow{
                 --antd-arrow-background-color:var(--theme-bg-reverse);
             }
         }
         `
        },
      ]
    },
    {
      key: 4,
      comp: "disabled tooltip",
      code: [
        {
          tab: 'js',
          code: `import { Button, Tooltip } from 'antd'
          import React, { useState } from 'react'
          
          function App() {
              const [disabled, setDisabled] = useState(true);
            return (
              <div
                  style={{
                  display:'flex',
                  alignItems:"center",
                  gap:"10px"
              }}
              >
                   <Button className='auf-button' block={false} onClick={() => setDisabled(!disabled)}>
                   {disabled ? 'Enable' : 'Disable'}</Button>
                <Tooltip overlayClassName="auf-tooltip-overlay" title={disabled ? '' : 'prompt text'}>
                  <span>Tooltip will show on mouse enter.</span>
                </Tooltip>
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import { Button, Tooltip } from 'antd'
          import React, { useState } from 'react'
          
          const App: React.FC = () => {
              const [disabled, setDisabled] = useState(true);
            return (
              <div
                  style={{
                  display:'flex',
                  alignItems:"center",
                  gap:"10px"
              }}
              >
                   <Button className='auf-button' block={false} onClick={() => setDisabled(!disabled)}>
                   {disabled ? 'Enable' : 'Disable'}</Button>
                <Tooltip overlayClassName="auf-tooltip-overlay" title={disabled ? '' : 'prompt text'}>
                  <span>Tooltip will show on mouse enter.</span>
                </Tooltip>
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'scss',
          code: `.auf-tooltip{
            color: var(--theme-bg-reverse);
         }
         
         .auf-tooltip-overlay{
             .ant-tooltip-content{
                 .ant-tooltip-inner{
         background-color: var(--theme-bg-reverse);
                     color: var(--theme-bg-primary);
                 }
             }
             .ant-tooltip-arrow{
                 --antd-arrow-background-color:var(--theme-bg-reverse);
             }
         }
         `
        },
      ]
    },
  ]
  return (
    <Layout title='tooltip'>
    <CompLayout title={"Basic Tooltip"} code={code[0]}>
<BasicTooltip />
    </CompLayout>
    <CompLayout title={"Tooltip Placement"} code={code[1]}>
<PlacementTooltip />
    </CompLayout>
    <CompLayout title={"Color Tooltip"} code={code[2]}>
<ColorTooltip />
    </CompLayout>
    <CompLayout title={"Disabled Tooltip"} code={code[3]}>
<DisabledTooltip />
    </CompLayout>
    </Layout>
  )
}

export default Tooltip