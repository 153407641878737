import React from 'react'
import CompLayout from './../Components/CompLayout';
import Layout from './../Components/Layout';
import SingleUpload from './../Components/Elements/Upload/SingleUpload';
import DragAndDrop from './../Components/Elements/Upload/DragAndDrop';
import MultipleUpload from './../Components/Elements/Upload/MultipleUpload';

function Upload() {
  const code =[
    {
      key: 1,
      comp: "single upload",
      code: [
        {
          tab: 'js',
          code: `import React from 'react'
          import { UploadOutlined } from '@ant-design/icons';
          import { Button, message, Upload } from 'antd';
          function App() {
          const props = {
            name: 'file',
            action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
            headers: {
              authorization: 'authorization-text',
            },
            onChange(info) {
              if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (info.file.status === 'done') {
                message.success(info.file.name, "file uploaded successfully");
              } else if (info.file.status === 'error') {
                message.error(info.file.name, "file upload failed.");
              }
            },
          };
            return (
              <Upload {...props} className='auf-upload'>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { UploadOutlined } from '@ant-design/icons';
          import type { UploadProps } from 'antd';
          import { Button, message, Upload } from 'antd';
          
          const props: UploadProps = {
            name: 'file',
            action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
            headers: {
              authorization: 'authorization-text',
            },
            onChange(info) {
              if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (info.file.status === 'done') {
                message.success(info.file.name, "file uploaded successfully");
              } else if (info.file.status === 'error') {
                message.error(info.file.name, "file upload failed.");
              }
            },
          };
          
          const App: React.FC = () => (
            <Upload {...props} className='auf-upload'>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-upload{
            .ant-btn{
                background-color: var(--theme-bg-primary);
                border-color: $gray;
                color: var(--theme-bg-reverse);
                &:hover{
                    border-color: var(--theme-nav-link-primary) !important;
                    color: var(--theme-nav-link-primary) !important;
                }
            }
        }
        
        .ant-upload-drag{
            border-color: var(--theme-bg-reverse) !important;
            &:hover{
                border-color: var(--theme-nav-link-primary) !important;
            }
            .ant-upload.ant-upload-btn{
        padding: 16px;
            }
            .anticon.anticon-inbox{
                    color: var(--theme-nav-link-primary) !important;
            }
            .ant-upload-text{
                color: var(--theme-bg-reverse) !important;
            }
            .ant-upload-hint{
                color: var(--theme-bg-reverse) !important;
            }
                }
         `
        },
      ]
    },
    {
      key: 2,
      comp: "drag and drop",
      code: [
        {
          tab: 'js',
          code: `import React from 'react'
          import { InboxOutlined } from '@ant-design/icons';
          import { message, Upload } from 'antd';
          const { Dragger } = Upload;
          function App() {
              const props = {
                  name: 'file',
                  multiple: true,
                  action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
                  onChange(info) {
                    const { status } = info.file;
                    if (status !== 'uploading') {
                      console.log(info.file, info.fileList);
                    }
                    if (info.file.status === 'done') {
                      message.success(info.file.name, "file uploaded successfully");
                    } else if (info.file.status === 'error') {
                      message.error(info.file.name, "file upload failed.");
                    }
                  },
                  onDrop(e) {
                    console.log('Dropped files', e.dataTransfer.files);
                  },
                };
            return (
              <Dragger {...props} className='auf-upload'> 
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                banned files.
              </p>
            </Dragger>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { InboxOutlined } from '@ant-design/icons';
          import type { UploadProps } from 'antd';
          import { message, Upload } from 'antd';
          
          const { Dragger } = Upload;
          
          const props: UploadProps = {
            name: 'file',
            multiple: true,
            action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
            onChange(info) {
              const { status } = info.file;
              if (status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (info.file.status === 'done') {
                message.success(info.file.name, "file uploaded successfully");
              } else if (info.file.status === 'error') {
                message.error(info.file.name, "file upload failed.");
              }
            },
            onDrop(e) {
              console.log('Dropped files', e.dataTransfer.files);
            },
          };
          
          const App: React.FC = () => (
            <Dragger {...props} className='auf-upload'> 
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibited from uploading company data or other
              banned files.
            </p>
          </Dragger>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-upload{
            .ant-btn{
                background-color: var(--theme-bg-primary);
                border-color: $gray;
                color: var(--theme-bg-reverse);
                &:hover{
                    border-color: var(--theme-nav-link-primary) !important;
                    color: var(--theme-nav-link-primary) !important;
                }
            }
        }
        
        .ant-upload-drag{
            border-color: var(--theme-bg-reverse) !important;
            &:hover{
                border-color: var(--theme-nav-link-primary) !important;
            }
            .ant-upload.ant-upload-btn{
        padding: 16px;
            }
            .anticon.anticon-inbox{
                    color: var(--theme-nav-link-primary) !important;
            }
            .ant-upload-text{
                color: var(--theme-bg-reverse) !important;
            }
            .ant-upload-hint{
                color: var(--theme-bg-reverse) !important;
            }
                }
         `
        },
      ]
    },
    {
      key: 3,
      comp: "multi upload",
      code: [
        {
          tab: 'js',
          code: `import React from 'react'
          import { UploadOutlined } from '@ant-design/icons';
          import { Button, message, Upload } from 'antd';
          function App() {
              const props = {
                  name: 'file',
                  MultipleUpload:true,
                  action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
                  headers: {
                    authorization: 'authorization-text',
                  },
                  onChange(info) {
                    if (info.file.status !== 'uploading') {
                      console.log(info.file, info.fileList);
                    }
                    if (info.file.status === 'done') {
                      message.success(info.file.name, "file uploaded successfully");
                    } else if (info.file.status === 'error') {
                      message.error(info.file.name, "file upload failed.");
                    }
                  },
                };
            return (
              <Upload {...props} className='auf-upload'>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { UploadOutlined } from '@ant-design/icons';
          import type { UploadProps } from 'antd';
          import { Button, message, Upload } from 'antd';
          
          const props: UploadProps = {
            name: 'file',
            MultipleUpload:true,
            action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
            headers: {
              authorization: 'authorization-text',
            },
            onChange(info) {
              if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (info.file.status === 'done') {
                message.success(info.file.name, "file uploaded successfully");
              } else if (info.file.status === 'error') {
                message.error(info.file.name, "file upload failed.");
              }
            },
          };
          
          const App: React.FC = () => (
            <Upload {...props} className='auf-upload'>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-upload{
            .ant-btn{
                background-color: var(--theme-bg-primary);
                border-color: $gray;
                color: var(--theme-bg-reverse);
                &:hover{
                    border-color: var(--theme-nav-link-primary) !important;
                    color: var(--theme-nav-link-primary) !important;
                }
            }
        }
        
        .ant-upload-drag{
            border-color: var(--theme-bg-reverse) !important;
            &:hover{
                border-color: var(--theme-nav-link-primary) !important;
            }
            .ant-upload.ant-upload-btn{
        padding: 16px;
            }
            .anticon.anticon-inbox{
                    color: var(--theme-nav-link-primary) !important;
            }
            .ant-upload-text{
                color: var(--theme-bg-reverse) !important;
            }
            .ant-upload-hint{
                color: var(--theme-bg-reverse) !important;
            }
                }
         `
        },
      ]
    },
  ]
  return (
    <Layout title='upload'>
    <CompLayout title={"single upload"} code={code[0]}>
<SingleUpload />
    </CompLayout>
    <CompLayout title={"drag and drop"}code={code[1]}>
<DragAndDrop />
    </CompLayout>
    <CompLayout title={"multiple upload"} code={code[2]}>
<MultipleUpload />
    </CompLayout>
    </Layout>
  )
}

export default Upload