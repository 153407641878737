import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import SingleSelect from '../Components/Elements/Select/SingleSelect'
import MultiSelect from '../Components/Elements/Select/MultiSelect'

function Select() {
    const code = [
        {
          key:1,
          comp:"single select",
          code:[
            {
              tab:'js',
              code:`import React from 'react'
              import { Select, Space } from 'antd';
              function App() {
                  const handleChange = (value) => {
                    console.log("selected" ,value);
                  };
                return (
                  <Select
                    defaultValue="lucy"
                    className='auf-select'
                    popupClassName='auf-select-popup'
                    style={{
                      width: 120,
                    }}
                    onChange={handleChange}
                    options={[
                      {
                        value: 'jack',
                        label: 'Jack',
                      },
                      {
                        value: 'lucy',
                        label: 'Lucy',
                      },
                      {
                        value: 'Yiminghe',
                        label: 'yiminghe',
                      },
                      {
                        value: 'Rose',
                        label: 'Rose',
                      },
                    ]}
                  />
                )
              }
              
              export default App `
            },
            {
              tab:'ts',
              code:`import React from 'react'
              import { Select, Space } from 'antd';
              const App: React.FC = () => (
                const handleChange = (value: string) => {
                    console.log("selected" ,value);
                  };
                return (
                  <Select
                    defaultValue="lucy"
                    className='auf-select'
                    popupClassName='auf-select-popup'
                    style={{
                      width: 120,
                    }}
                    onChange={handleChange}
                    options={[
                      {
                        value: 'jack',
                        label: 'Jack',
                      },
                      {
                        value: 'lucy',
                        label: 'Lucy',
                      },
                      {
                        value: 'Yiminghe',
                        label: 'yiminghe',
                      },
                      {
                        value: 'Rose',
                        label: 'Rose',
                      },
                    ]}
                  />
                )
              }
              
              export default App `
            },
            {
              tab:'scss',
              code:`.auf-select{
                .ant-select-selector{
                    background-color: var(--theme-bg-primary) !important;
                    border-color: var(--theme-bg-reverse) !important;
                    &:hover{
                        border-color: var(--theme-nav-link-primary) !important;
                    }
                }
                .ant-select-selection-item{
                    color: var(--theme-bg-reverse);
                }
                .ant-select-arrow{
                    color: var(--theme-bg-reverse)
                }
                &.ant-select-focused{
                    .ant-select-selector{
                        border-color: var(--theme-nav-link-primary) !important;
                    }
                    .ant-select-selection-item{
                        color:var(--theme-bg-reverse);
                    }
                }
            }
            
            .auf-select-popup{
                // display: block !important;
                background-color: var(--theme-bg-primary);
                border: 1px solid var(--theme-bg-reverse);
                .ant-select-item.ant-select-item-option{
                    &:hover{
                        background-color: $gray;
                    }
                }
                .ant-select-item-option-content{
                    color: var(--theme-bg-reverse);
                }
                .ant-select-item.ant-select-item-option.ant-select-item-option-selected{
                    background-color: var(--theme-nav-link-primary);
                }
            }`
            }
          ]
        },
        {
          key:2,
          comp:"multi select",
          code:[
            {
              tab:'js',
              code:`import { Select } from 'antd';
              import React from 'react'
              
              function App() {
                  const options = [];
              for (let i = 10; i < 36; i++) {
                options.push({
                  label: i.toString(36) + i,
                  value: i.toString(36) + i,
                });
              }
              const handleChange = (value) => {
                console.log("selected" ,value);
              };
                return (
                  <Select
                  className='auf-select'
                  popupClassName='auf-select-popup'
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                    }}
                    placeholder="Please select"
                    defaultValue={['a10', 'c12']}
                    onChange={handleChange}
                    options={options}
                  />
                )
              }
              
              export default App `
            },
            {
              tab:'ts',
              code:`import { Select } from 'antd';
              import React from 'react'
              import type { SelectProps } from 'antd';

              
              function App() {
                const options: SelectProps['options'] = [];
                for (let i = 10; i < 36; i++) {
                    options.push({
                      label: i.toString(36) + i,
                      value: i.toString(36) + i,
                    });
                  }
                  const handleChange = (value: string[]) => {
                    console.log("selected" ,value);
                  };
                return (
                  <Select
                  className='auf-select'
                  popupClassName='auf-select-popup'
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                    }}
                    placeholder="Please select"
                    defaultValue={['a10', 'c12']}
                    onChange={handleChange}
                    options={options}
                  />
                )
              }
              
              export default App `
            },
            {
              tab:'scss',
              code:`.auf-select{
                .ant-select-selector{
                    background-color: var(--theme-bg-primary) !important;
                    border-color: var(--theme-bg-reverse) !important;
                    &:hover{
                        border-color: var(--theme-nav-link-primary) !important;
                    }
                }
                .ant-select-selection-item{
                    color: var(--theme-bg-reverse);
                }
                .ant-select-arrow{
                    color: var(--theme-bg-reverse)
                }
                &.ant-select-focused{
                    .ant-select-selector{
                        border-color: var(--theme-nav-link-primary) !important;
                    }
                    .ant-select-selection-item{
                        color:var(--theme-bg-reverse);
                    }
                }
            &.ant-select-multiple{
                .ant-select-selection-item{
                    background-color: var(--theme-bg-reverse);
                }
                .ant-select-selection-item-content{
                    color: var(--theme-bg-primary);
                }
                .ant-select-selection-item-remove{
                    color: var(--theme-bg-primary);
                    &:hover{
                        color: var(--theme-nav-link-primary);
                    }
                }
                .ant-select-clear{
                    top: 45%;
                    width: 16px;
                    height: 16px;
                background-color: var(--theme-bg-primary);
                }
            }
            }
            
            .auf-select-popup{
                // display: block !important;
                background-color: var(--theme-bg-primary);
                border: 1px solid var(--theme-bg-reverse);
                .ant-select-item.ant-select-item-option{
                    &:hover{
                        background-color: $gray;
                    }
                }
                .ant-select-item-option-content{
                    color: var(--theme-bg-reverse);
                }
                .ant-select-item.ant-select-item-option.ant-select-item-option-selected{
                    background-color: var(--theme-nav-link-primary);
                }
            }`
            }
          ]
        }
      ]
  return (
    <Layout title='select'>
    <CompLayout title={"single select"} code={code[0]}>
<SingleSelect />
    </CompLayout>
    <CompLayout title={"multi select"} code={code[1]}>
<MultiSelect />
    </CompLayout>
    </Layout>
  )
}

export default Select