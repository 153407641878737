import { Rate } from 'antd'
import React, { useState } from 'react'

function BasicRate() {
    const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
    const [value, setValue] = useState(3);
  return (
    <div style={{
        display:'flex',
        flexDirection:'column',
        gap:'20px'
    }}>
        <div style={{
            display:'flex',
            flexDirection:'column',
            gap:'5px'
        }}>
  <h6 className="comp-title-name">Basic</h6>
            <Rate className='auf-rate' />
        </div>
        <div style={{
            display:'flex',
            flexDirection:'column',
            gap:'5px'
        }}>
     <h6 className="comp-title-name">read only</h6>
        <Rate className='auf-rate' disabled defaultValue={2} />
        </div>
        <div style={{
            display:'flex',
            flexDirection:'column',
            gap:'5px'
        }}>
       <h6 className="comp-title-name">Show copywriting</h6>
       <Rate className='auf-rate' tooltips={desc} onChange={setValue} value={value} />
        </div>
    </div>
  )
}

export default BasicRate