import { Table } from 'antd';
import React from 'react'

function BasicTable() {
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (text) => <a>{text}</a>,
        },
        {
          title: 'Age',
          dataIndex: 'age',
          key: 'age',
        },
        {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <div>
              <a>Invite {record.name}</a>
              <a>Delete</a>
            </div>
          ),
        },
      ];
      const data = [
        {
          key: '1',
          name: 'John Brown',
          age: 32,
          address: 'New York No. 1 Lake Park',
        },
        {
          key: '2',
          name: 'Jim Green',
          age: 42,
          address: 'London No. 1 Lake Park',
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sydney No. 1 Lake Park',
        },
      ];
  return (
    <div style={{
      width:'1000px'
    }}>
       <div style={{
      width:'100%'
    }}>
      <Table className='auf-table' columns={columns} dataSource={data} />
    </div>
    </div>
  )
}

export default BasicTable