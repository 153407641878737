import { Tag } from 'antd'
import React from 'react'
import { CloseCircleOutlined } from '@ant-design/icons';
function BasicTag() {
    const log = (e) => {
        console.log(e);
      };
      const preventDefault = (e) => {
        e.preventDefault();
        console.log('Clicked! But prevent default.');
      };
  return (
    <div style={{
        display:'flex',
        height:'fit-content'
    }}>
         <Tag className='auf-tag'>Tag 1</Tag>
    <Tag className='auf-tag'>
      <a href="https://github.com/ant-design/ant-design/issues/1862">Link</a>
    </Tag>
    <Tag className='auf-tag' closeIcon onClose={preventDefault}>
      Prevent Default
    </Tag>
    <Tag className='auf-tag' closeIcon={<CloseCircleOutlined />} onClose={log}>
      Tag 2
    </Tag>
    </div>
  )
}

export default BasicTag