import { TimePicker } from 'antd';
import React from 'react'

function TwelveHours() {
    const onChange = (time, timeString) => {
        console.log(time, timeString);
      };
  return (
    <div>
    <TimePicker className='auf-time-picker' use12Hours format="h:mm:ss A" onChange={onChange} />
    <br /><br />
    <TimePicker className='auf-time-picker' use12Hours format="h:mm a" onChange={onChange} />
    </div>
  )
}

export default TwelveHours