// src/App.js
import React from "react";
import Layout from "../Components/Layout";
import PrimaryButton from "../Components/Elements/Button/PrimaryButton";
import ButtonWithIcon from "../Components/Elements/Button/ButtonWithIcon";
import CompLayout from "../Components/CompLayout";
import TextButton from "../Components/Elements/Button/TextButton";
import LinkButton from "../Components/Elements/Button/LinkButton";
import HrefButton from "../Components/Elements/Button/HrefButton";
import GhostButton from "../Components/Elements/Button/GhostButton";
import DisableButton from "../Components/Elements/Button/DisableButton";
import LoadingButton from "../Components/Elements/Button/LoadingButton";
import BlockButton from "../Components/Elements/Button/BlockButton";
import DangerButton from "../Components/Elements/Button/DangerButton";
import CircleButton from "../Components/Elements/Button/CircleButton";
import BackToTop from "../Components/Elements/Button/BackToTop";

function Button() {
  
  const code = [
    {
      key: 1,
      comp: "Primary Button",
      code: [
        {
          tab: 'js',
          code: `import React from "react";
          import { Button } from "antd";
          function App({ children, onClick }) {
            return (
              <Button type="primary" onClick={onClick} className="auf-button">
                {children}
              </Button>
            );
          }
          
          export default App;
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Button } from 'antd';
          
          const App: React.FC = ({ children }) => (
              <Button className='auf-button' type="primary">{children}</Button>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-button.ant-btn-primary,.ant-btn-default.auf-button.ant-btn-primary{
            background-color: var(--theme-nav-link-primary);
            color: var(--theme-bg-primary);
            &:hover{
                background-color: var(--theme-comp-hover) !important;
                color: var(--theme-bg-primary) !important;
            }
            }`
        },
      ]
    },
    {
      key: 2,
      comp: "text Button",
      code: [
        {
          tab: 'js',
          code: `import { Button } from 'antd'
          import React from 'react'
          
          function App({children}) {
            return (
              <Button type="text" className='auf-button'>{children}</Button>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Button } from 'antd';
          
          const App: React.FC = ({ children }) => (
            <Button type="text" className='auf-button'>{children}</Button>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-button.ant-btn-text{
            color: var(--theme-bg-reverse);
            &:hover{
                background-color: var(--theme-nav-link-primary) !important;
                color: var(--theme-bg-primary) !important;
            }
        }`
        },
      ]
    },
    {
      key: 2,
      comp: "link Button",
      code: [
        {
          tab: 'js',
          code: `import { Button } from 'antd'
          import React from 'react'
          
          function App({children}) {
            return (
              <Button type="link" className='auf-button'>{children}</Button>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Button } from 'antd';
          
          const App: React.FC = ({ children }) => (
            <Button type="link" className='auf-button'>{children}</Button>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-button.ant-btn-link{
            color: var(--theme-nav-link-primary);
            
            &:hover{
                color: var(--theme-comp-hover) !important;
            }
            }`
        },
      ]
    },
    {
      key: 3,
      comp: "href Button",
      code: [
        {
          tab: 'js',
          code: `import { Button } from 'antd'
          import React from 'react'
          
          function App({children,href="#"}) {
            return (
              <Button href={href} className='auf-button'>{children}</Button>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Button } from 'antd';
          
          const App: React.FC = ({ children }) => (
            <Button href={href} className='auf-button'>{children}</Button>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `
          .auf-button.ant-btn-default{
          background-color: var(--theme-bg-primary);
          color: var(--theme-bg-reverse);
          border-color: var(--theme-nav-link-secondary);
          &:hover{
              border-color: var(--theme-nav-link-primary) !important;
              color: var(--theme-nav-link-primary) !important;
          }}`
        },
      ]
    },
    {
      key: 4,
      comp: "Button With Icon",
      code: [
        {
          tab: 'js',
          code: `import React from 'react'
          import { Button } from "antd";
          import { SearchOutlined } from '@ant-design/icons';
          function App({children,onClick}) {
            return (
              <Button
              icon={<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
              viewBox="0 0 20 20" fill="none">
              <circle cx="9.80492" cy="9.80492" r="7.49047" 
              stroke="#17232A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.0156 15.4043L17.9523 18.3334" stroke="#17232A" 
              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>}
            onClick={onClick}
            className="auf-button"
            >
              {children}
            </Button>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Button } from 'antd';
          
          const App: React.FC = ({ children }) => (
            <Button
            icon={<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
            viewBox="0 0 20 20" fill="none">
            <circle cx="9.80492" cy="9.80492" r="7.49047" 
            stroke="#17232A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.0156 15.4043L17.9523 18.3334" stroke="#17232A" 
            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>}
          onClick={onClick}
          className="auf-button"
          >
            {children}
          </Button>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-button.ant-btn-default, .auf-button.ant-btn-circle{
            background-color: var(--theme-bg-primary);
            color: var(--theme-bg-reverse);
            border-color: var(--theme-nav-link-secondary);
            &:hover{
                border-color: var(--theme-nav-link-primary) !important;
                color: var(--theme-nav-link-primary) !important;
            }
            .ant-btn-icon{
                svg{
                    circle{
                        stroke: var(--theme-bg-reverse);
                    }
                    path{
                        stroke: var(--theme-bg-reverse);
                    }
                }
            }
            }`
        },
      ]
    },
    {
      key: 5,
      comp: "Circle Button",
      code: [
        {
          tab: 'js',
          code: `import { Button } from 'antd'
          import React from 'react'
          
          function App() {
            return (
              <Button className='auf-button' shape="circle" 
              icon={<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 20 20" fill="none">
              <circle cx="9.80492" cy="9.80492" r="7.49047" stroke="#17232A" strokeWidth="1.5" 
              strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.0156 15.4043L17.9523 18.3334" stroke="#17232A" strokeWidth="1.5" 
              strokeLinecap="round" strokeLinejoin="round"/>
            </svg>} />
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Button } from 'antd';
          
          const App: React.FC = ({ children }) => (
            <Button className='auf-button' shape="circle" 
            icon={<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 20 20" fill="none">
            <circle cx="9.80492" cy="9.80492" r="7.49047" stroke="#17232A" strokeWidth="1.5" 
            strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.0156 15.4043L17.9523 18.3334" stroke="#17232A" strokeWidth="1.5" 
            strokeLinecap="round" strokeLinejoin="round"/>
          </svg>} />
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-button.ant-btn-circle{
            background-color: var(--theme-bg-primary);
            color: var(--theme-bg-reverse);
            border-color: var(--theme-nav-link-secondary);
            &:hover{
                border-color: var(--theme-nav-link-primary) !important;
                color: var(--theme-nav-link-primary) !important;
            }
            .ant-btn-icon{
                svg{
                    circle{
                        stroke: var(--theme-bg-reverse);
                    }
                    path{
                        stroke: var(--theme-bg-reverse);
                    }
                }
            }
            }`
        },
      ]
    },
    {
      key: 6,
      comp: "Ghost Button",
      code: [
        {
          tab: 'js',
          code: `import { Button } from 'antd'
          import React from 'react'
          
          function App({children}) {
            return (
              <Button type='primary' ghost className='auf-button'>{children}</Button>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Button } from 'antd';
          
          const App: React.FC = ({ children }) => (
            <Button type='primary' ghost className='auf-button'>{children}</Button>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-button.ant-btn-background-ghost{
            background-color: transparent;
            color: var(--theme-nav-link-primary);
            border-color: var(--theme-nav-link-primary);
            &:hover{
                background-color: transparent !important;
                color: var(--theme-nav-link-primary) !important;
                border-color: var(--theme-nav-link-primary) !important;
            }
            }`
        },
      ]
    },
    {
      key: 7,
      comp: "Disable Button",
      code: [
        {
          tab: 'js',
          code: `import { Button } from 'antd'
          import React from 'react'
          
          function App({children}) {
            return (
              <Button disabled className='auf-button'>{children}</Button>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Button } from 'antd';
          
          const App: React.FC = ({ children }) => (
            <Button disabled className='auf-button'>{children}</Button>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-button.ant-btn-default{
            background-color: var(--theme-bg-primary);
            color: var(--theme-bg-reverse);
            border-color: var(--theme-nav-link-secondary);
            &:hover{
                border-color: var(--theme-nav-link-primary) !important;
                color: var(--theme-nav-link-primary) !important;
            }}`
        },
      ]
    },
    {
      key: 8,
      comp: "Loading Button",
      code: [
        {
          tab: 'js',
          code: `import { Button } from 'antd'
          import React from 'react'
          
          function App({children}) {
            return (
              <Button loading className='auf-button'>{children}</Button>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Button } from 'antd';
          
          const App: React.FC = ({ children }) => (
            <Button loading className='auf-button'>{children}</Button>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-button.ant-btn-default, .auf-button.ant-btn-circle{
            background-color: var(--theme-bg-primary);
            color: var(--theme-bg-reverse);
            border-color: var(--theme-nav-link-secondary);
            &:hover{
                border-color: var(--theme-nav-link-primary) !important;
                color: var(--theme-nav-link-primary) !important;
            }
            .ant-btn-icon{
                svg{
                    circle{
                        stroke: var(--theme-bg-reverse);
                    }
                    path{
                        stroke: var(--theme-bg-reverse);
                    }
                }
            }
            }`
        },
      ]
    },
    {
      key: 9,
      comp: "Block Button",
      code: [
        {
          tab: 'js',
          code: `import { Button } from 'antd'
          import React from 'react'
          
          function App({children}) {
            return (
              <Button type="primary" block className='auf-button'>{children}</Button>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Button } from 'antd';
          
          const App: React.FC = ({ children }) => (
            <Button type="primary" block className='auf-button'>{children}</Button>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-button.ant-btn-primary{
            background-color: var(--theme-nav-link-primary);
            color: var(--theme-bg-primary);
            &:hover{
                background-color: var(--theme-comp-hover) !important;
                color: var(--theme-bg-primary) !important;
            }`
        },
      ]
    },
    {
      key: 10,
      comp: "Danger Button",
      code: [
        {
          tab: 'js',
          code: `import { Button } from 'antd'
          import React from 'react'
          
          function App({children}) {
            return (
              <Button danger className='auf-button'>{children}</Button>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Button } from 'antd';
          
          const App: React.FC = ({ children }) => (
            <Button danger className='auf-button'>{children}</Button>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-button.ant-btn-dangerous{
            background-color: var(--theme-bg-primary);
            border-color: #ff4d4f;
            color: #ff4d4f;
        }`
        },
      ]
    },
    {
      key: 11,
      comp: "Back To Top Button",
      code: [
        {
          tab: 'js',
          code: `import { Button } from 'antd';
          import { ArrowUpOutlined } from '@ant-design/icons';
          import React from 'react';
          
          function BackToTop() {
            const scrollToTop = () => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            };
          
            return (
              <Button onClick={scrollToTop} className='auf-button'>
                <ArrowUpOutlined />
              </Button>
            );
          }
          
          export default BackToTop;
          
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Button } from 'antd';
          
          const App: React.FC = ({ children }) => (
            <Button onClick={scrollToTop} className='auf-button'>
            <ArrowUpOutlined />
          </Button>
          );
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-button.ant-btn-default
            background-color: var(--theme-bg-primary);
            color: var(--theme-bg-reverse);
            border-color: var(--theme-nav-link-secondary);
            &:hover{
                border-color: var(--theme-nav-link-primary) !important;
                color: var(--theme-nav-link-primary) !important;
            }
            .ant-btn-icon{
                svg{
                    circle{
                        stroke: var(--theme-bg-reverse);
                    }
                    path{
                        stroke: var(--theme-bg-reverse);
                    }
                }
            }
            }`
        },
      ]
    },
  ];
  return (
    <Layout title="Buttons">
      <CompLayout title={"Primary Button"} code={code[0]}>
      <PrimaryButton>Button</PrimaryButton>
      </CompLayout>
      <CompLayout title={"Text Button"} code={code[1]}>
      <TextButton>Text Button</TextButton>
      </CompLayout>
      <CompLayout title={"Link Button"} code={code[2]}>
      <LinkButton>Link Button</LinkButton>
      </CompLayout>
      <CompLayout title={"Href Button"} code={code[3]}>
      <HrefButton>Href Button</HrefButton>
      </CompLayout>
      <CompLayout title={"Button With Icon"} code={code[4]}>
      <ButtonWithIcon>Button</ButtonWithIcon> 
      </CompLayout>
      <CompLayout title={"Circle Button"} code={code[5]}>
      <CircleButton />
      </CompLayout>
      <CompLayout title={"Ghost Button"} code={code[6]}>
      <GhostButton>Ghost Button</GhostButton>
      </CompLayout>
      <CompLayout title={"Disable Button"} code={code[7]}>
      <DisableButton>Disable Button</DisableButton>
      </CompLayout>
      <CompLayout title={"Loading Button"} code={code[8]}>
      <LoadingButton>Loading Button</LoadingButton>
      </CompLayout>
      <CompLayout title={"Block Button"} code={code[9]}>
      <BlockButton>Block Button</BlockButton>
      </CompLayout>
      <CompLayout title={"Danger Button"} code={code[10]}>
      <DangerButton>Danger Button</DangerButton>
      </CompLayout>
      <CompLayout title={"Back to top Button"} code={code[11]}>
      <BackToTop />
      </CompLayout>
    </Layout>
  );
}

export default Button;
