import { Button, Drawer } from 'antd';
import React, { useState } from 'react'

function Basic() {
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };
    return (
      <>
        <Button className='auf-button' type="primary" onClick={showDrawer}>
          Open
        </Button>
        <Drawer className='auf-drawer' title="Basic Drawer" onClose={onClose} open={open}>
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Drawer>
      </>
    );
}

export default Basic