import { Breadcrumb } from 'antd'
import React from 'react'

function Basic() {
  return (
    <Breadcrumb
    className='auf-breadcrumb'
    items={[
      {
        title: <a href="">Home</a>,
      },
      {
        title: <a href="">Application Center</a>,
      },
      {
        title: <a href="">Application List</a>,
      },
      {
        title: 'An Application',
      },
    ]}
  />
  )
}

export default Basic