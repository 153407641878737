import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import Basic from '../Components/Elements/Drawer/Basic'
import Placement from '../Components/Elements/Drawer/Placement'

function Drawer() {
  const code = [
    {
      key:1,
      comp:"Basic drawer",
      code:[
        {
          tab:'js',
          code:`import { Button, Drawer } from 'antd';
          import React, { useState } from 'react'
          
          function App() {
              const [open, setOpen] = useState(false);
              const showDrawer = () => {
                setOpen(true);
              };
              const onClose = () => {
                setOpen(false);
              };
              return (
                <>
                  <Button className='auf-button' type="primary" onClick={showDrawer}>
                    Open
                  </Button>
                  <Drawer className='auf-drawer' title="Basic Drawer" onClose={onClose} open={open}>
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                  </Drawer>
                </>
              );
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import { Button, Drawer } from 'antd';
          import React, { useState } from 'react'
          
          const App: React.FC = () => (
              const [open, setOpen] = useState(false);
              const showDrawer = () => {
                setOpen(true);
              };
              const onClose = () => {
                setOpen(false);
              };
              return (
                <>
                  <Button className='auf-button' type="primary" onClick={showDrawer}>
                    Open
                  </Button>
                  <Drawer className='auf-drawer' title="Basic Drawer" onClose={onClose} open={open}>
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                  </Drawer>
                </>
              );
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-drawer{
            background-color: var(--theme-bg-primary) !important;
            .ant-drawer-header{
                border-color: $gray;
            }
            .ant-drawer-close{
                color: var(--theme-bg-reverse);
                &:hover{
                    color: var(--theme-nav-link-primary);
                }
            }
            .ant-drawer-title{
                color: var(--theme-bg-reverse);
            }
            .ant-drawer-body{
                p{
                    color: var(--theme-bg-reverse);
                }
            }
        }`
        }
      ]
    },
    {
      key:2,
      comp:"placement drawer",
      code:[
        {
          tab:'js',
          code:`import { Button, Drawer, Radio } from 'antd';
          import React, { useState } from 'react'
          
          function App() {
              const [open, setOpen] = useState(false);
              const [placement, setPlacement] = useState('left');
              const showDrawer = () => {
                setOpen(true);
              };
              const onClose = () => {
                setOpen(false);
              };
              const onChange = (e) => {
                setPlacement(e.target.value);
              };
              return (
                <div
                style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'20px'
                }}
                >
                    <Radio.Group value={placement} onChange={onChange}>
                      <Radio className='auf-radio' value="top">top</Radio>
                      <Radio className='auf-radio' value="right">right</Radio>
                      <Radio className='auf-radio' value="bottom">bottom</Radio>
                      <Radio className='auf-radio' value="left">left</Radio>
                    </Radio.Group>
                    <Button className='auf-button' type="primary" onClick={showDrawer}>
                      Open
                    </Button>
                  <Drawer
                  className='auf-drawer'
                    title="Basic Drawer"
                    placement={placement}
                    closable={false}
                    onClose={onClose}
                    open={open}
                    key={placement}
                  >
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                  </Drawer>
                </div>
              );
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import { Button, Drawer, Radio } from 'antd';
          import type { DrawerProps, RadioChangeEvent } from 'antd';
          import React, { useState } from 'react'
          
          function App() {
              const [open, setOpen] = useState(false);
              const [placement, setPlacement] = useState<DrawerProps['placement']>('left');

              const showDrawer = () => {
                setOpen(true);
              };
              const onClose = () => {
                setOpen(false);
              };
              const onChange = (e: RadioChangeEvent) => {
                setPlacement(e.target.value);
              };
              return (
                <div
                style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'20px'
                }}
                >
                    <Radio.Group value={placement} onChange={onChange}>
                      <Radio className='auf-radio' value="top">top</Radio>
                      <Radio className='auf-radio' value="right">right</Radio>
                      <Radio className='auf-radio' value="bottom">bottom</Radio>
                      <Radio className='auf-radio' value="left">left</Radio>
                    </Radio.Group>
                    <Button className='auf-button' type="primary" onClick={showDrawer}>
                      Open
                    </Button>
                  <Drawer
                  className='auf-drawer'
                    title="Basic Drawer"
                    placement={placement}
                    closable={false}
                    onClose={onClose}
                    open={open}
                    key={placement}
                  >
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                  </Drawer>
                </div>
              );
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-drawer{
            background-color: var(--theme-bg-primary) !important;
            .ant-drawer-header{
                border-color: $gray;
            }
            .ant-drawer-close{
                color: var(--theme-bg-reverse);
                &:hover{
                    color: var(--theme-nav-link-primary);
                }
            }
            .ant-drawer-title{
                color: var(--theme-bg-reverse);
            }
            .ant-drawer-body{
                p{
                    color: var(--theme-bg-reverse);
                }
            }
        }`
        }
      ]
    },
  ]
  return (
    <Layout title='drawer'>
    <CompLayout title={"Basic drawer"} code={code[0]}>
<Basic />
    </CompLayout>
    <CompLayout title={"drawer placement"} code={code[1]}>
<Placement />
    </CompLayout>
    </Layout>
  )
}

export default Drawer