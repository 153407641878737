import { Carousel } from 'antd';
import React from 'react';

function ImageSlider() {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  const images = [
    'https://via.placeholder.com/800x400',
    'https://via.placeholder.com/800x400',
    'https://via.placeholder.com/800x400',
    'https://via.placeholder.com/800x400',
  ];
  const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    width:'500px'
  };
  return (
    <div style={{
      width:'800px',
    }}>
      <Carousel className='auf-slider' afterChange={onChange}>
      {images.map((imageUrl, index) => (
        <div key={index}>
          <img
            src={imageUrl}
            alt={`Slide ${index + 1}`}
            style={{ width: '100%', height: '160px', objectFit: 'cover' }}
          />
        </div>
      ))}
    </Carousel>
    </div>
  );
}

export default ImageSlider;
