import React from 'react'
import { HeartOutlined } from '@ant-design/icons';
import { Rate } from 'antd';
function OtherCharacter() {
  return (
    <div style={{
        display:'flex',
        flexDirection:'column',
        gap:'5px'
    }}>
        <Rate className='auf-rate' character={<HeartOutlined />} />
    <Rate
      character="A"
      className='auf-rate'
    />
    <Rate className='auf-rate' character="好" />
    </div>
  )
}

export default OtherCharacter