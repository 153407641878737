import { DatePicker } from 'antd';
import React from 'react'

function BasicDatePicker() {
    const onChange = (date, dateString) => {
        console.log(date, dateString);
      };
  return (
    <div
    style={{
        display:'flex',
        flexDirection:'column',
        gap:'10px'
    }}
    >
         <DatePicker popupClassName='auf-datepicker-popup' className='auf-datepicker' onChange={onChange} />
    <DatePicker popupClassName='auf-datepicker-popup' className='auf-datepicker' onChange={onChange} picker="week" />
    <DatePicker popupClassName='auf-datepicker-popup' className='auf-datepicker' onChange={onChange} picker="month" />
    <DatePicker popupClassName='auf-datepicker-popup' className='auf-datepicker' onChange={onChange} picker="quarter" />
    <DatePicker popupClassName='auf-datepicker-popup' className='auf-datepicker' onChange={onChange} picker="year" />
    </div>
  )
}

export default BasicDatePicker