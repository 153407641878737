import React from 'react'
import { Select, Space } from 'antd';
function SingleSelect() {
    const handleChange = (value) => {
      console.log(`selected ${value}`);
    };
  return (
    <Select
      defaultValue="lucy"
      className='auf-select'
      popupClassName='auf-select-popup'
      style={{
        width: 120,
      }}
      onChange={handleChange}
      options={[
        {
          value: 'jack',
          label: 'Jack',
        },
        {
          value: 'lucy',
          label: 'Lucy',
        },
        {
          value: 'Yiminghe',
          label: 'yiminghe',
        },
        {
          value: 'Rose',
          label: 'Rose',
        },
      ]}
    />
  )
}

export default SingleSelect