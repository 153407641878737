import { Button, Modal } from 'antd';
import React from 'react'

function StaticPopup() {
    const info = () => {
        Modal.info({
          className:'auf-modal',
          title: 'This is a notification message',
          content: (
            <div>
              <p>some messages...some messages...</p>
              <p>some messages...some messages...</p>
            </div>
          ),
          onOk() {},
        });
      };
      const success = () => {
        Modal.success({
          className:'auf-modal',
          content: 'some messages...some messages...',
        });
      };
      const error = () => {
        Modal.error({
          className:'auf-modal',
          title: 'This is an error message',
          content: 'some messages...some messages...',
        });
      };
      const warning = () => {
        Modal.warning({
          className:'auf-modal',
          title: 'This is a warning message',
          content: 'some messages...some messages...',
        });
      };
  return (
    <div
    style={{
        display:'flex',
        gap:"10px"
    }}
    >
        <Button className='auf-button' onClick={info}>Info</Button>
    <Button className='auf-button' onClick={success}>Success</Button>
    <Button className='auf-button' onClick={error}>Error</Button>
    <Button className='auf-button' onClick={warning}>Warning</Button>
    </div>
  )
}

export default StaticPopup