import { Checkbox } from 'antd'
import React from 'react'

function DisabledCheckbox() {
  return (
    <div style={{
        display:'flex',
        gap:"10px"
    }}>
    <Checkbox className='auf-checkbox' defaultChecked={false} disabled />
    <Checkbox className='auf-checkbox' indeterminate disabled />
    <Checkbox className='auf-checkbox' defaultChecked disabled />
  </div>
  )
}

export default DisabledCheckbox