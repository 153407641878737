import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import BasicRate from '../Components/Elements/Rate/Basic'
import OtherCharacter from '../Components/Elements/Rate/OtherCharacter'

function Rate() {
  const code = [
    {
      key:1,
      comp:"Basic rate",
      code:[
        {
          tab:'js',
          code:`import { Rate } from 'antd'
          import React, { useState } from 'react'
          
          function App() {
              const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
              const [value, setValue] = useState(3);
            return (
              <div style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'20px'
              }}>
                  <div style={{
                      display:'flex',
                      flexDirection:'column',
                      gap:'5px'
                  }}>
            <h6 className="comp-title-name">Basic</h6>
                      <Rate className='auf-rate' />
                  </div>
                  <div style={{
                      display:'flex',
                      flexDirection:'column',
                      gap:'5px'
                  }}>
               <h6 className="comp-title-name">read only</h6>
                  <Rate className='auf-rate' disabled defaultValue={2} />
                  </div>
                  <div style={{
                      display:'flex',
                      flexDirection:'column',
                      gap:'5px'
                  }}>
                 <h6 className="comp-title-name">Show copywriting</h6>
                 <Rate className='auf-rate' tooltips={desc} onChange={setValue} value={value} />
                  </div>
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import { Rate } from 'antd'
          import React, { useState } from 'react'
          
          const App: React.FC = () =>{
              const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
              const [value, setValue] = useState(3);
            return (
              <div style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'20px'
              }}>
                  <div style={{
                      display:'flex',
                      flexDirection:'column',
                      gap:'5px'
                  }}>
            <h6 className="comp-title-name">Basic</h6>
                      <Rate className='auf-rate' />
                  </div>
                  <div style={{
                      display:'flex',
                      flexDirection:'column',
                      gap:'5px'
                  }}>
               <h6 className="comp-title-name">read only</h6>
                  <Rate className='auf-rate' disabled defaultValue={2} />
                  </div>
                  <div style={{
                      display:'flex',
                      flexDirection:'column',
                      gap:'5px'
                  }}>
                 <h6 className="comp-title-name">Show copywriting</h6>
                 <Rate className='auf-rate' tooltips={desc} onChange={setValue} value={value} />
                  </div>
              </div>
            )
          }
          
          export default BasicRate
          `
        },
        {
          tab:'scss',
          code:`.auf-rate{
            .ant-rate-star{
                .ant-rate-star-second{
                    color: var(--theme-bg-primary);
                }
                &.ant-rate-star-full{
                    .ant-rate-star-second{
                        color: var(--theme-nav-link-primary)
                    }
                }
            }
        }`
        }
      ]
    },
    {
      key:2,
      comp:"Other Characters",
      code:[
        {
          tab:'js',
          code:`import React from 'react'
          import { HeartOutlined } from '@ant-design/icons';
          import { Rate } from 'antd';
          function App() {
            return (
              <div style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'5px'
              }}>
                  <Rate className='auf-rate' character={<HeartOutlined />} />
              <Rate
                character="A"
                className='auf-rate'
              />
              <Rate className='auf-rate' character="好" />
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import React from 'react'
          import { HeartOutlined } from '@ant-design/icons';
          import { Rate } from 'antd';
          const App: React.FC = () =>
            return (
              <div style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'5px'
              }}>
                  <Rate className='auf-rate' character={<HeartOutlined />} />
              <Rate
                character="A"
                className='auf-rate'
              />
              <Rate className='auf-rate' character="好" />
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-rate{
            .ant-rate-star{
                .ant-rate-star-second{
                    color: var(--theme-bg-primary);
                }
                &.ant-rate-star-full{
                    .ant-rate-star-second{
                        color: var(--theme-nav-link-primary)
                    }
                }
            }
        }`
        }
      ]
    },
  ]
  return (
    <Layout title='Rate'>
    <CompLayout title={"Basic Rate"} code={code[0]}>
    <BasicRate />
    </CompLayout>
    <CompLayout title={"Other characters"} code={code[1]}>
    <OtherCharacter />
    </CompLayout>
    </Layout>
  )
}

export default Rate