import { Button, Tooltip } from 'antd';
import React from 'react'

function ColorTooltip() {
    const colors = [
        'pink',
        'red',
        'yellow',
        'orange',
        'cyan',
        'green',
        'blue',
        'purple',
        'geekblue',
        'magenta',
        'volcano',
        'gold',
        'lime',
      ];
      const customColors = ['#f50', '#2db7f5', '#87d068', '#108ee9'];
  return (
    <div
    style={{
        display:'flex',
        flexDirection:"column",
        gap:"20px",
        alignItems:"center"
    }}
    >
<div className=""     style={{
        display:'flex',
        gap:"5px"
    }}>
{colors.map((color) => (
        <Tooltip  title="prompt text" color={color} key={color}>
          <Button className='auf-button'>{color}</Button>
        </Tooltip>
      ))}
</div>
<div className=""     style={{
        display:'flex',
        gap:"5px"
    }}>
{customColors.map((color) => (
        <Tooltip title="prompt text" color={color} key={color}>
          <Button className='auf-button'>{color}</Button>
        </Tooltip>
      ))}
</div>
    </div>
  )
}

export default ColorTooltip