import React from 'react'
import Layout from './../Components/Layout';
import CompLayout from './../Components/CompLayout';
import Basic from '../Components/Elements/Popup/Basic';
import StaticPopup from '../Components/Elements/Popup/StaticPopup';

function Popup() {
  const code =[
    {
      key: 1,
      comp: "basic popup",
      code: [
        {
          tab: 'js',
          code: `import { Button, Modal } from 'antd';
          import React, { useState } from 'react'
          
          function App() {
              const [isModalOpen, setIsModalOpen] = useState(false);
              const showModal = () => {
                setIsModalOpen(true);
              };
              const handleOk = () => {
                setIsModalOpen(false);
              };
              const handleCancel = () => {
                setIsModalOpen(false);
              };
              const footer = (
                <>
                  <Button onClick={handleCancel} className='auf-button' >Cancel</Button>
                  <Button onClick={handleOk} className='auf-button ant-btn-primary'>Ok</Button>
                </>
              )
            return (
              <>
                <Button className='auf-button' type="primary" onClick={showModal}>
                  Open Modal
                </Button>
                <Modal className='auf-modal'  title="Basic Modal" open={isModalOpen} 
                onOk={handleOk} onCancel={handleCancel}>
                  <p>Some contents...</p>
                  <p>Some contents...</p>
                  <p>Some contents...</p>
                </Modal>
              </>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import { Button, Modal } from 'antd';
          import React, { useState } from 'react'
          
          const App: React.FC = () => {
              const [isModalOpen, setIsModalOpen] = useState(false);
              const showModal = () => {
                setIsModalOpen(true);
              };
              const handleOk = () => {
                setIsModalOpen(false);
              };
              const handleCancel = () => {
                setIsModalOpen(false);
              };
              const footer = (
                <>
                  <Button onClick={handleCancel} className='auf-button' >Cancel</Button>
                  <Button onClick={handleOk} className='auf-button ant-btn-primary'>Ok</Button>
                </>
              )
            return (
              <>
                <Button className='auf-button' type="primary" onClick={showModal}>
                  Open Modal
                </Button>
                <Modal className='auf-modal'  title="Basic Modal" open={isModalOpen} 
                onOk={handleOk} onCancel={handleCancel}>
                  <p>Some contents...</p>
                  <p>Some contents...</p>
                  <p>Some contents...</p>
                </Modal>
              </>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'scss',
          code: `.auf-modal{
            .ant-modal-content{
                background-color: var(--theme-bg-primary);
            }
            .ant-modal-close{
                &:hover{
                    background-color: $gray;
                }
                .anticon.anticon-close{
                    color: var(--theme-bg-reverse);
                }
            }
            .ant-modal-header{
                background-color: transparent;
            }
            .ant-modal-title{
                color: var(--theme-bg-reverse);
            }
            .ant-modal-body{
                p{
                    color: var(--theme-bg-reverse);
                }
            }
            .ant-modal-footer{
                .ant-btn-default{
                    background-color: var(--theme-bg-primary);
        color: var(--theme-bg-reverse);
        border-color: var(--theme-nav-link-secondary);
        &:hover{
            border-color: var(--theme-nav-link-primary) !important;
            color: var(--theme-nav-link-primary) !important;
        }
                }
                .ant-btn-primary{
                    background-color: var(--theme-nav-link-primary);
                    color: var(--theme-bg-primary);
                    &:hover{
                        background-color: var(--theme-comp-hover) !important;
                        color: var(--theme-bg-primary) !important;
                    }
                }
            }
        
        
            .ant-modal-confirm-title{
        color: var(--theme-bg-reverse);
            }
            .ant-modal-confirm-content{
        color: var(--theme-bg-reverse);
            }
            .ant-modal-confirm-btns{
                    .ant-btn-primary{
                        background-color: var(--theme-nav-link-primary);
                        color: var(--theme-bg-primary);
                        &:hover{
                            background-color: var(--theme-comp-hover) !important;
                            color: var(--theme-bg-primary) !important;
                        }
                    }
            }
        }
         `
        },
      ]
    },
    {
      key: 2,
      comp: "static popup",
      code: [
        {
          tab: 'js',
          code: `import { Button, Modal } from 'antd';
          import React from 'react'
          
          function App() {
              const info = () => {
                  Modal.info({
                    className:'auf-modal',
                    title: 'This is a notification message',
                    content: (
                      <div>
                        <p>some messages...some messages...</p>
                        <p>some messages...some messages...</p>
                      </div>
                    ),
                    onOk() {},
                  });
                };
                const success = () => {
                  Modal.success({
                    className:'auf-modal',
                    content: 'some messages...some messages...',
                  });
                };
                const error = () => {
                  Modal.error({
                    className:'auf-modal',
                    title: 'This is an error message',
                    content: 'some messages...some messages...',
                  });
                };
                const warning = () => {
                  Modal.warning({
                    className:'auf-modal',
                    title: 'This is a warning message',
                    content: 'some messages...some messages...',
                  });
                };
            return (
              <div
              style={{
                  display:'flex',
                  gap:"10px"
              }}
              >
                  <Button className='auf-button' onClick={info}>Info</Button>
              <Button className='auf-button' onClick={success}>Success</Button>
              <Button className='auf-button' onClick={error}>Error</Button>
              <Button className='auf-button' onClick={warning}>Warning</Button>
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'js',
          code: `import { Button, Modal } from 'antd';
          import React from 'react'
          
          const App: React.FC = () => {
              const info = () => {
                  Modal.info({
                    className:'auf-modal',
                    title: 'This is a notification message',
                    content: (
                      <div>
                        <p>some messages...some messages...</p>
                        <p>some messages...some messages...</p>
                      </div>
                    ),
                    onOk() {},
                  });
                };
                const success = () => {
                  Modal.success({
                    className:'auf-modal',
                    content: 'some messages...some messages...',
                  });
                };
                const error = () => {
                  Modal.error({
                    className:'auf-modal',
                    title: 'This is an error message',
                    content: 'some messages...some messages...',
                  });
                };
                const warning = () => {
                  Modal.warning({
                    className:'auf-modal',
                    title: 'This is a warning message',
                    content: 'some messages...some messages...',
                  });
                };
            return (
              <div
              style={{
                  display:'flex',
                  gap:"10px"
              }}
              >
                  <Button className='auf-button' onClick={info}>Info</Button>
              <Button className='auf-button' onClick={success}>Success</Button>
              <Button className='auf-button' onClick={error}>Error</Button>
              <Button className='auf-button' onClick={warning}>Warning</Button>
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'scss',
          code: `.auf-modal{
            .ant-modal-content{
                background-color: var(--theme-bg-primary);
            }
            .ant-modal-close{
                &:hover{
                    background-color: $gray;
                }
                .anticon.anticon-close{
                    color: var(--theme-bg-reverse);
                }
            }
            .ant-modal-header{
                background-color: transparent;
            }
            .ant-modal-title{
                color: var(--theme-bg-reverse);
            }
            .ant-modal-body{
                p{
                    color: var(--theme-bg-reverse);
                }
            }
            .ant-modal-footer{
                .ant-btn-default{
                    background-color: var(--theme-bg-primary);
        color: var(--theme-bg-reverse);
        border-color: var(--theme-nav-link-secondary);
        &:hover{
            border-color: var(--theme-nav-link-primary) !important;
            color: var(--theme-nav-link-primary) !important;
        }
                }
                .ant-btn-primary{
                    background-color: var(--theme-nav-link-primary);
                    color: var(--theme-bg-primary);
                    &:hover{
                        background-color: var(--theme-comp-hover) !important;
                        color: var(--theme-bg-primary) !important;
                    }
                }
            }
        
        
            .ant-modal-confirm-title{
        color: var(--theme-bg-reverse);
            }
            .ant-modal-confirm-content{
        color: var(--theme-bg-reverse);
            }
            .ant-modal-confirm-btns{
                    .ant-btn-primary{
                        background-color: var(--theme-nav-link-primary);
                        color: var(--theme-bg-primary);
                        &:hover{
                            background-color: var(--theme-comp-hover) !important;
                            color: var(--theme-bg-primary) !important;
                        }
                    }
            }
        }
         `
        },
      ]
    },
  ]
  return (
    <Layout title='popup'>
    <CompLayout title={"basic popup"} code={code[0]}>
<Basic />
    </CompLayout>
    <CompLayout title={"static popup"} code={code[1]}>
<StaticPopup />
    </CompLayout>
    </Layout>
  )
}

export default Popup