import { Button, ConfigProvider, Tooltip } from 'antd'
import React from 'react'

function PlacementTooltip() {
    const text = "prompt text"
    const buttonWidth = 80;
  return (
    <ConfigProvider
    button={{
      style: {
        width: buttonWidth,
        margin: 4,
      },
    }}
  >
    <div className="demo">
      <div
        style={{
          marginInlineStart: buttonWidth,
          whiteSpace: 'nowrap',
        }}
      >
        <Tooltip overlayClassName="auf-tooltip-overlay" placement="topLeft" title={text}>
          <Button className='auf-button'>TL</Button>
        </Tooltip>
        <Tooltip overlayClassName="auf-tooltip-overlay" placement="top" title={text}>
          <Button className='auf-button'>Top</Button>
        </Tooltip>
        <Tooltip overlayClassName="auf-tooltip-overlay" placement="topRight" title={text}>
          <Button className='auf-button'>TR</Button>
        </Tooltip>
      </div>
      <div
        style={{
          width: buttonWidth,
          float: 'inline-start',
        }}
      >
        <Tooltip overlayClassName="auf-tooltip-overlay" placement="leftTop" title={text}>
          <Button className='auf-button'>LT</Button>
        </Tooltip>
        <Tooltip overlayClassName="auf-tooltip-overlay" placement="left" title={text}>
          <Button className='auf-button'>Left</Button>
        </Tooltip>
        <Tooltip overlayClassName="auf-tooltip-overlay" placement="leftBottom" title={text}>
          <Button className='auf-button'>LB</Button>
        </Tooltip>
      </div>
      <div
        style={{
          width: buttonWidth,
          marginInlineStart: buttonWidth * 4 + 24,
        }}
      >
        <Tooltip overlayClassName="auf-tooltip-overlay" placement="rightTop" title={text}>
          <Button className='auf-button'>RT</Button>
        </Tooltip>
        <Tooltip overlayClassName="auf-tooltip-overlay" placement="right" title={text}>
          <Button className='auf-button'>Right</Button>
        </Tooltip>
        <Tooltip overlayClassName="auf-tooltip-overlay" placement="rightBottom" title={text}>
          <Button className='auf-button'>RB</Button>
        </Tooltip>
      </div>
      <div
        style={{
          marginInlineStart: buttonWidth,
          clear: 'both',
          whiteSpace: 'nowrap',
        }}
      >
        <Tooltip overlayClassName="auf-tooltip-overlay" placement="bottomLeft" title={text}>
          <Button className='auf-button'>BL</Button>
        </Tooltip>
        <Tooltip overlayClassName="auf-tooltip-overlay" placement="bottom" title={text}>
          <Button className='auf-button'>Bottom</Button>
        </Tooltip>
        <Tooltip overlayClassName="auf-tooltip-overlay" placement="bottomRight" title={text}>
          <Button className='auf-button'>BR</Button>
        </Tooltip>
      </div>
    </div>
  </ConfigProvider>
  )
}

export default PlacementTooltip