import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import BasicTimePicker from '../Components/Elements/TimePicker/BasicTimePicker'
import TimeRange from '../Components/Elements/TimePicker/TimeRange'
import TwelveHours from '../Components/Elements/TimePicker/TwelveHours'

function TimePicker() {
  const code = [
    {
      key:1,
      comp:"Basic timepicker",
      code:[
        {
          tab:'js',
          code:`import React from 'react';
          import dayjs from 'dayjs';
          import customParseFormat from 'dayjs/plugin/customParseFormat';
          import { TimePicker } from 'antd';
          dayjs.extend(customParseFormat);
          const onChange = (time, timeString) => {
            console.log(time, timeString);
          };
          function App() {
            return (
              <TimePicker className='auf-time-picker' 
              onChange={onChange} defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')} />
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import React from 'react';
          import dayjs from 'dayjs';
          import customParseFormat from 'dayjs/plugin/customParseFormat';
          import { TimePicker } from 'antd';          
          dayjs.extend(customParseFormat);
          const onChange = (time: Dayjs, timeString: string) => {
            console.log(time, timeString);
          };
          const App: React.FC = () => (
            return (
              <TimePicker className='auf-time-picker' 
              onChange={onChange} defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')} />
            )
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-time-picker{
            border-color: $gray;
            background-color: var(--theme-bg-primary);
            &:hover{
                border-color: var(--theme-nav-link-primary);
            }
            .ant-picker-input{
                input{
                    color: var(--theme-bg-reverse);
                    &::placeholder{
                        color: var(--theme-bg-reverse);
                    }
                }
            }
            .ant-picker-suffix{
                .anticon{
                    color: var(--theme-bg-reverse);
                }
            }
            &.ant-picker-focused{
                border-color: var(--theme-nav-link-primary);
                box-shadow: none;
            }
            .ant-picker-clear{
                background-color: var(--theme-bg-primary);
            }
            .ant-picker-active-bar{
                background-color: var(--theme-nav-link-primary);
            }
        }`
        }
      ]
    },
    {
      key:2,
      comp:"time range",
      code:[
        {
          tab:'js',
          code:`import { TimePicker } from 'antd'
          import React from 'react'
          
          function App() {
            return (
              <TimePicker.RangePicker className='auf-time-picker' />
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import { TimePicker } from 'antd'
          import React from 'react'
          
          const App: React.FC = () => (
            return (
              <TimePicker.RangePicker className='auf-time-picker' />
            )
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-time-picker{
            border-color: $gray;
            background-color: var(--theme-bg-primary);
            &:hover{
                border-color: var(--theme-nav-link-primary);
            }
            .ant-picker-input{
                input{
                    color: var(--theme-bg-reverse);
                    &::placeholder{
                        color: var(--theme-bg-reverse);
                    }
                }
            }
            .ant-picker-suffix{
                .anticon{
                    color: var(--theme-bg-reverse);
                }
            }
            &.ant-picker-focused{
                border-color: var(--theme-nav-link-primary);
                box-shadow: none;
            }
            .ant-picker-clear{
                background-color: var(--theme-bg-primary);
            }
            .ant-picker-active-bar{
                background-color: var(--theme-nav-link-primary);
            }
        }`
        }
      ]
    },
    {
      key:3,
      comp:"12 hours",
      code:[
        {
          tab:'js',
          code:`import { TimePicker } from 'antd';
          import React from 'react'
          
          function App() {
              const onChange = (time, timeString) => {
                  console.log(time, timeString);
                };
            return (
              <div>
              <TimePicker className='auf-time-picker' use12Hours format="h:mm:ss A" onChange={onChange} />
              <br /><br />
              <TimePicker className='auf-time-picker' use12Hours format="h:mm a" onChange={onChange} />
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'js',
          code:`import { TimePicker } from 'antd';
          import React from 'react'
          
          const App: React.FC = () => (
            const onChange = (time: Dayjs, timeString: string) => {
              console.log(time, timeString);
            };
            return (
              <div>
              <TimePicker className='auf-time-picker' use12Hours format="h:mm:ss A" onChange={onChange} />
              <br /><br />
              <TimePicker className='auf-time-picker' use12Hours format="h:mm a" onChange={onChange} />
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-time-picker{
            border-color: $gray;
            background-color: var(--theme-bg-primary);
            &:hover{
                border-color: var(--theme-nav-link-primary);
            }
            .ant-picker-input{
                input{
                    color: var(--theme-bg-reverse);
                    &::placeholder{
                        color: var(--theme-bg-reverse);
                    }
                }
            }
            .ant-picker-suffix{
                .anticon{
                    color: var(--theme-bg-reverse);
                }
            }
            &.ant-picker-focused{
                border-color: var(--theme-nav-link-primary);
                box-shadow: none;
            }
            .ant-picker-clear{
                background-color: var(--theme-bg-primary);
            }
            .ant-picker-active-bar{
                background-color: var(--theme-nav-link-primary);
            }
        }`
        }
      ]
    },
  ]
  return (
    <Layout title='time picker'>
    <CompLayout title={"basic"} code={code[0]}>
<BasicTimePicker />
    </CompLayout>
    <CompLayout title={"Time range"} code={code[1]}>
<TimeRange />
    </CompLayout>
    <CompLayout title={"12 hours"} code={code[2]}>
<TwelveHours />
    </CompLayout>
    </Layout>
  )
}

export default TimePicker