// App.js
import React, { useContext, useEffect } from 'react';
import AppRouter from './AppRouter';
import { GlobalContext, GlobalProvider} from './provider/GlobalProvider'
function App() {
  return (
        <GlobalProvider>
        <AppRouter />
        </GlobalProvider>
  );
}

export default App;
