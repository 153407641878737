import { Radio } from 'antd'
import React from 'react'

function RadioSize() {
  return (
    <div >
 <Radio.Group defaultValue="a" size="large" className='auf-radio'>
      <Radio.Button  value="a">Hangzhou</Radio.Button>
      <Radio.Button  value="b">Shanghai</Radio.Button>
      <Radio.Button  value="c">Beijing</Radio.Button>
      <Radio.Button  value="d">Chengdu</Radio.Button>
    </Radio.Group>
    <br />
    <br />
    <Radio.Group defaultValue="a" className='auf-radio'>
      <Radio.Button value="a">Hangzhou</Radio.Button>
      <Radio.Button value="b">Shanghai</Radio.Button>
      <Radio.Button value="c">Beijing</Radio.Button>
      <Radio.Button value="d">Chengdu</Radio.Button>
    </Radio.Group>
    <br />
    <br />
    <Radio.Group defaultValue="a" size="small" className='auf-radio'>
      <Radio.Button value="a">Hangzhou</Radio.Button>
      <Radio.Button value="b">Shanghai</Radio.Button>
      <Radio.Button value="c">Beijing</Radio.Button>
      <Radio.Button value="d">Chengdu</Radio.Button>
    </Radio.Group>
    </div>
  )
}

export default RadioSize