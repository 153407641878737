import { Radio } from 'antd';
import React, { useState } from 'react'
const plainOptions = ['Apple', 'Pear', 'Orange'];
const options = [
  {
    label: 'Apple',
    value: 'Apple',
  },
  {
    label: 'Pear',
    value: 'Pear',
  },
  {
    label: 'Orange',
    value: 'Orange',
    title: 'Orange',
  },
];
const optionsWithDisabled = [
  {
    label: 'Apple',
    value: 'Apple',
  },
  {
    label: 'Pear',
    value: 'Pear',
  },
  {
    label: 'Orange',
    value: 'Orange',
    disabled: true,
  },
];
function RadioStyles() {
    const [value1, setValue1] = useState('Apple');
    const [value2, setValue2] = useState('Apple');
    const onChange1 = ({ target: { value } }) => {
        console.log('radio1 checked', value);
        setValue1(value);
      };
      const onChange2 = ({ target: { value } }) => {
        console.log('radio2 checked', value);
        setValue2(value);
      };
  return (
    <div>
<Radio.Group className='auf-radio' options={options} onChange={onChange1} value={value1} optionType="button" />
<br /><br />
      <Radio.Group
      className='auf-radio'
        options={optionsWithDisabled}
        onChange={onChange2}
        value={value2}
        optionType="button"
        buttonStyle="solid"
      />
    </div>
  )
}

export default RadioStyles