import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import Search from '../Components/Elements/SearchBox/Search'
import SearchWithLoading from '../Components/Elements/SearchBox/SearchWithLoading'

function SearchBox() {
  const code = [
    {
      key:1,
      comp:"search",
      code:[
        {
          tab:'js',
          code:`import React from 'react';
          import { AudioOutlined } from '@ant-design/icons';
          import { Input, Space } from 'antd';
          function App() {
              const { Search } = Input;
              const suffix = (
                <AudioOutlined
                  style={{
                    fontSize: 16,
                    color: '#1677ff',
                  }}
                />
              );
              const onSearch = (value, _e, info) => console.log(info?.source, value);
            return (
              <div style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'10px'
              }}>
                   <Search className='auf-search' placeholder="input search text" 
                   onSearch={onSearch} style={{ width: 200 }} />
              <Search className='auf-search' placeholder="input search text" allowClear 
              onSearch={onSearch} style={{ width: 200 }} />
              <Search
              className='auf-search'
                addonBefore="https://"
                placeholder="input search text"
                allowClear
                onSearch={onSearch}
                style={{ width: 304 }}
              />
              <Search className='auf-search' placeholder="input search text" onSearch={onSearch} enterButton />
              <Search
              className='auf-search'
                placeholder="input search text"
                allowClear
                enterButton="Search"
                size="large"
                onSearch={onSearch}
              />
              <Search
              className='auf-search'
                placeholder="input search text"
                enterButton="Search"
                size="large"
                suffix={suffix}
                onSearch={onSearch}
              />
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import React from 'react';
          import { AudioOutlined } from '@ant-design/icons';
          import { Input, Space } from 'antd';
          import type { SearchProps } from 'antd/es/input/Search';

          const App: React.FC = () => (
              const { Search } = Input;
              const suffix = (
                <AudioOutlined
                  style={{
                    fontSize: 16,
                    color: '#1677ff',
                  }}
                />
              );
              const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);

            return (
              <div style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'10px'
              }}>
                   <Search className='auf-search' placeholder="input search text" 
                   onSearch={onSearch} style={{ width: 200 }} />
              <Search className='auf-search' placeholder="input search text" allowClear 
              onSearch={onSearch} style={{ width: 200 }} />
              <Search
              className='auf-search'
                addonBefore="https://"
                placeholder="input search text"
                allowClear
                onSearch={onSearch}
                style={{ width: 304 }}
              />
              <Search className='auf-search' placeholder="input search text" onSearch={onSearch} enterButton />
              <Search
              className='auf-search'
                placeholder="input search text"
                allowClear
                enterButton="Search"
                size="large"
                onSearch={onSearch}
              />
              <Search
              className='auf-search'
                placeholder="input search text"
                enterButton="Search"
                size="large"
                suffix={suffix}
                onSearch={onSearch}
              />
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-search{
            .ant-input{
                background-color: var(--theme-bg-primary);
                color: var(--theme-bg-reverse);
        border-color: $gray;
        
        &:hover{
            border-color: var(--theme-nav-link-primary);
        }
                &::placeholder{
                    color: $gray;
                }
                &:focus{
                    border-color: var(--theme-nav-link-primary);
                    box-shadow: none;
                }
            }
        
            .ant-input-search-button{
                color: var(--theme-bg-reverse) !important;
                background-color: var(--theme-bg-primary);
                &:hover{
                    border-color: var(--theme-nav-link-primary) !important;
                    color: var(--theme-nav-link-primary) !important;
                }
            }
            .ant-input-affix-wrapper{
                background-color: var(--theme-bg-primary);
                border-color: $gray;
                &.ant-input-affix-wrapper-focused{
                    border-color: var(--theme-nav-link-primary);
                    box-shadow: none;
                }
                &:hover{
                    border-color: var(--theme-nav-link-primary);
                }
        
            }
            .ant-input-group-addon{
                background-color: var(--theme-bg-reverse);
                border-color: $gray;
                color: var(--theme-bg-primary);
            }
            &.ant-input-search-with-button{
                .ant-input-search-button{
                    background-color: var(--theme-nav-link-primary);
        color: var(--theme-bg-reverse) !important;
                    &:hover{
                        background-color: var(--theme-comp-hover);
                    }
                }
            }
            .ant-input-suffix{
                .anticon.anticon-audio{
                    color: var(--theme-bg-reverse) !important;
                }
            }
        }`
        }
      ]
    },
    {
      key:2,
      comp:"loading search",
      code:[
        {
          tab:'js',
          code:`import React from 'react'
          import { Input } from 'antd';
          const { Search } = Input;
          function App () {
            return (
              <div style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'10px'
              }}>
                   <Search className='auf-search' placeholder="input search loading default" loading />
              <Search className='auf-search' placeholder="input search loading with enterButton" 
              loading enterButton />
              <Search className='auf-search' placeholder="input search text" enterButton="Search" 
              size="large" loading />
              </div>
            )
          }
          
          export default App 
          `
        },
        {
          tab:'ts',
          code:`import React from 'react'
          import { Input } from 'antd';
          const { Search } = Input;
          const App: React.FC = () => (
            return (
              <div style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'10px'
              }}>
                   <Search className='auf-search' placeholder="input search loading default" loading />
              <Search className='auf-search' placeholder="input search loading with enterButton" 
              loading enterButton />
              <Search className='auf-search' placeholder="input search text" enterButton="Search" 
              size="large" loading />
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-search{
            .ant-input{
                background-color: var(--theme-bg-primary);
                color: var(--theme-bg-reverse);
        border-color: $gray;
        
        &:hover{
            border-color: var(--theme-nav-link-primary);
        }
                &::placeholder{
                    color: $gray;
                }
                &:focus{
                    border-color: var(--theme-nav-link-primary);
                    box-shadow: none;
                }
            }
        
            .ant-input-search-button{
                color: var(--theme-bg-reverse) !important;
                background-color: var(--theme-bg-primary);
                &:hover{
                    border-color: var(--theme-nav-link-primary) !important;
                    color: var(--theme-nav-link-primary) !important;
                }
            }
            .ant-input-affix-wrapper{
                background-color: var(--theme-bg-primary);
                border-color: $gray;
                &.ant-input-affix-wrapper-focused{
                    border-color: var(--theme-nav-link-primary);
                    box-shadow: none;
                }
                &:hover{
                    border-color: var(--theme-nav-link-primary);
                }
        
            }
            .ant-input-group-addon{
                background-color: var(--theme-bg-reverse);
                border-color: $gray;
                color: var(--theme-bg-primary);
            }
            &.ant-input-search-with-button{
                .ant-input-search-button{
                    background-color: var(--theme-nav-link-primary);
        color: var(--theme-bg-reverse) !important;
                    &:hover{
                        background-color: var(--theme-comp-hover);
                    }
                }
            }
            .ant-input-suffix{
                .anticon.anticon-audio{
                    color: var(--theme-bg-reverse) !important;
                }
            }
        }`
        }
      ]
    },
  ]
  return (
    <Layout title='search'>
    <CompLayout title={"search box"} code={code[0]}>
<Search />
    </CompLayout>
    <CompLayout title={"search box with loading"} code={code[1]}>
<SearchWithLoading />
    </CompLayout>
    </Layout>
  )
}

export default SearchBox