import React, { useContext, useEffect } from "react";
import style from './layout.module.scss'
import { Dropdown, Menu } from "antd";
import { GlobalContext } from "../provider/GlobalProvider";
import { Link, useLocation } from "react-router-dom";
function Layout({ children,title = "Component" }) {
  const {theme,themeChanger} = useContext(GlobalContext)
  const items = [
    {
      label: 'Light',
      key: '1',
    },
    {
      label: 'Dark',
      key: '2',
    },
  ];
  const location = useLocation();
  const Links = [
    {
      key:1,
      icon:"",
      link:"/",
      title:"Button"
    },
    {
      key:2,
      icon:"",
      link:"/toggle",
      title:"Toggle"
    },
    {
      key:3,
      icon:"",
      link:"/tooltip",
      title:"Tooltip"
    },
    {
      key:4,
      icon:"",
      link:"/checkbox",
      title:"Checkbox"
    },
    {
      key:5,
      icon:"",
      link:"/collapse",
      title:"Collapse"
    },
    {
      key:6,
      icon:"",
      link:"/radio",
      title:"Radio Button"
    },
    {
      key:7,
      icon:"",
      link:"/upload",
      title:"Upload"
    },
    {
      key:8,
      icon:"",
      link:"/popup",
      title:"Popup"
    },
    {
      key:9,
      icon:"",
      link:"/date",
      title:"Date Picker"
    },
    {
      key:10,
      icon:"",
      link:"/time",
      title:"Time Picker"
    },
    {
      key:11,
      icon:"",
      link:"/table",
      title:"Table"
    },
    {
      key:12,
      icon:"",
      link:"/tab",
      title:"Tab"
    },
    {
      key:13,
      icon:"",
      link:"/card",
      title:"Card"
    },
    {
      key:14,
      icon:"",
      link:"/drawer",
      title:"Drawer"
    },
    {
      key:15,
      icon:"",
      link:"/slider",
      title:"Slider"
    },
    {
      key:16,
      icon:"",
      link:"/search",
      title:"SearchBox"
    },
    {
      key:17,
      icon:"",
      link:"/form",
      title:"Form Field"
    },
    {
      key:18,
      icon:"",
      link:"/breadcrumb",
      title:"Breadcrumb"
    },
    {
      key:19,
      icon:"",
      link:"/tag",
      title:"Tag"
    },
    {
      key:20,
      icon:"",
      link:"/rate",
      title:"Rate"
    },
    {
      key:21,
      icon:"",
      link:"/select",
      title:"Select"
    },
  ]
  const handleMenuClick = (e) => {
    themeChanger(e.key)
  };
  const menuItem = (
    <Menu onClick={handleMenuClick}>
      {items.map((item) => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);
  return (
    <div className={style["main-container"]}>
      <div className={style["side-panel"]}>
        <div className={style["panel-content"]}>
          <Link to={'/'} className={style["logo"]}>
<img src={theme == 'light' ? "/images/svg/logo.svg" : "/images/svg/logo-dark.svg"} alt="" />
          </Link>
          <nav className={style["nav"]}>
{
  Links.map((link)=>{
    return(
      <Link key={link.key} to={link.link} className={`${style["nav-link"]} ${location.pathname == link.link && `${style["active"]}`}`}>
                {link.title} (Antd)
              </Link>
    )
  })
}
          </nav>
        </div>
      </div>
      <div className={style["main-content"]}>
        <header className={style["header"]}>
        <h3 className={style["comp-title"]}>{title}</h3>
        <Dropdown className="theme-drop" overlay={menuItem} placement="bottomLeft" trigger={['click']}>
         <button>Theme</button>
        </Dropdown>
        </header>
        <div className={style["content-container"]}>
          <div className={style["content"]}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
