import { Checkbox } from 'antd';
import React from 'react'

function CheckboxGroup() {
    const plainOptions = ['Apple', 'Pear', 'Orange'];
  return (
    <Checkbox.Group className='auf-checkbox' options={plainOptions} defaultValue={['Apple']}  />
  )
}

export default CheckboxGroup