import React from 'react'
import { Switch } from 'antd';
function Basic() {
    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
      };
  return (
    <Switch className='auf-toggle' defaultChecked onChange={onChange} />
  )
}

export default Basic