import React from 'react'
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;
function RangePickerComp() {
  return (
    <div
    style={{
        display:'flex',
        flexDirection:'column',
        gap:'10px'
    }}
    >
         <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker'/>
    <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker' showTime />
    <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker'picker="week" />
    <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker'picker="month" />
    <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker'picker="quarter" />
    <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker'picker="year" />
    </div>
  )
}

export default RangePickerComp