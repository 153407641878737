import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import ImageSlider from '../Components/Elements/Slider/ImageSlider'
import VideoSlider from '../Components/Elements/Slider/VideoSlider'

function Slider() {
  const code = [
    {
      key: 1,
      comp: "basic checkbox",
      code: [
        {
          tab: 'js',
          code: `import { Carousel } from 'antd';
          import React from 'react';
          
          function App() {
            const onChange = (currentSlide) => {
              console.log(currentSlide);
            };
          
            const images = [
              'https://via.placeholder.com/800x400',
              'https://via.placeholder.com/800x400',
              'https://via.placeholder.com/800x400',
              'https://via.placeholder.com/800x400',
            ];
            const contentStyle = {
              margin: 0,
              height: '160px',
              color: '#fff',
              lineHeight: '160px',
              textAlign: 'center',
              background: '#364d79',
              width:'500px'
            };
            return (
              <div style={{
                width:'800px',
              }}>
                <Carousel className='auf-slider' afterChange={onChange}>
                {images.map((imageUrl, index) => (
                  <div key={index}>
                    <img
                      src={imageUrl}
                      alt={"Slide" index + 1}
                      style={{ width: '100%', height: '160px', objectFit: 'cover' }}
                    />
                  </div>
                ))}
              </Carousel>
              </div>
            );
          }
          
          export default App;
          
          `
        },
        {
          tab: 'ts',
          code: `import React from 'react';
          import { Carousel } from 'antd';
          
          const contentStyle: React.CSSProperties = {
            margin: 0,
            height: '160px',
            color: '#fff',
            lineHeight: '160px',
            textAlign: 'center',
            background: '#364d79',
          };
          
          const App: React.FC = () => {

            const images = [
              'https://via.placeholder.com/800x400',
              'https://via.placeholder.com/800x400',
              'https://via.placeholder.com/800x400',
              'https://via.placeholder.com/800x400',
            ];
            s
            const onChange = (currentSlide: number) => {
              console.log(currentSlide);
            };
          
            return (
              <div style={{
                width:'800px',
              }}>
                <Carousel className='auf-slider' afterChange={onChange}>
                {images.map((imageUrl, index) => (
                  <div key={index}>
                    <img
                      src={imageUrl}
                      alt={"Slide" index + 1}
                      style={{ width: '100%', height: '160px', objectFit: 'cover' }}
                    />
                  </div>
                ))}
              </Carousel>
              </div>
            );
          };
          
          export default App;
          `
        },
        {
          tab: 'scss',
          code: `.auf-slider{
            .slick-dots{
                li{
                    &:hover{
                        button{
                            background-color: var(--theme-bg-reverse);
                        }
                    }
                    &.slick-active{
                        button{
                            background-color: var(--theme-nav-link-primary);
                        }
                    }
                }
            }
        }
         `
        },
      ]
    },
  ]
  return (
    <Layout title='slider'>
    <CompLayout title={"image slider"} code={code[0]}>
<ImageSlider />
    </CompLayout>
    {/* <CompLayout title={"video slider"}>
<VideoSlider />
    </CompLayout> */}
    </Layout>
  )
}

export default Slider