import React from 'react'
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;
const onChange = (value, dateString) => {
  console.log('Selected Time: ', value);
  console.log('Formatted Selected Time: ', dateString);
};
const onOk = (value) => {
  console.log('onOk: ', value);
};
function ChooseTime() {
  return (
    <div
    style={{
        display:'flex',
        flexDirection:'column',
        gap:'10px'
    }}
    >
        <DatePicker popupClassName='auf-datepicker-popup' className='auf-datepicker' showTime onChange={onChange} onOk={onOk} />
    <RangePicker
    popupClassName='auf-datepicker-popup' className='auf-datepicker'
      showTime={{
        format: 'HH:mm',
      }}
      format="YYYY-MM-DD HH:mm"
      onChange={onChange}
      onOk={onOk}
    />
    </div>
  )
}

export default ChooseTime