import { Button, Drawer, Radio } from 'antd';
import React, { useState } from 'react'

function Placement() {
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('left');
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };
    const onChange = (e) => {
      setPlacement(e.target.value);
    };
    return (
      <div
      style={{
        display:'flex',
        flexDirection:'column',
        gap:'20px'
      }}
      >
          <Radio.Group value={placement} onChange={onChange}>
            <Radio className='auf-radio' value="top">top</Radio>
            <Radio className='auf-radio' value="right">right</Radio>
            <Radio className='auf-radio' value="bottom">bottom</Radio>
            <Radio className='auf-radio' value="left">left</Radio>
          </Radio.Group>
          <Button className='auf-button' type="primary" onClick={showDrawer}>
            Open
          </Button>
        <Drawer
        className='auf-drawer'
          title="Basic Drawer"
          placement={placement}
          closable={false}
          onClose={onClose}
          open={open}
          key={placement}
        >
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Drawer>
      </div>
    );
}

export default Placement