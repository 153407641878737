import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import BasicDatePicker from '../Components/Elements/DatePicker/BasicDatePicker'
import RangePickerComp from '../Components/Elements/DatePicker/RangePicker'
import ChooseTime from '../Components/Elements/DatePicker/ChooseTime'

function DatePicker() {
  const code = [
    {
      key:1,
      comp:"Basic datepicker",
      code:[
        {
          tab:'js',
          code:`import { DatePicker } from 'antd';
          import React from 'react'
          
          function App() {
              const onChange = (date, dateString) => {
                  console.log(date, dateString);
                };
            return (
              <div
              style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'10px'
              }}
              >
                   <DatePicker popupClassName='auf-datepicker-popup' 
                   className='auf-datepicker' onChange={onChange} />
              <DatePicker popupClassName='auf-datepicker-popup' 
              className='auf-datepicker' onChange={onChange} picker="week" />
              <DatePicker popupClassName='auf-datepicker-popup' 
              className='auf-datepicker' onChange={onChange} picker="month" />
              <DatePicker popupClassName='auf-datepicker-popup' 
              className='auf-datepicker' onChange={onChange} picker="quarter" />
              <DatePicker popupClassName='auf-datepicker-popup' 
              className='auf-datepicker' onChange={onChange} picker="year" />
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import { DatePicker } from 'antd';
          import React from 'react'
          
          const App: React.FC = () => (
            const onChange: DatePickerProps['onChange'] = (date, dateString) => {
              console.log(date, dateString);
            };
            return (
              <div
              style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'10px'
              }}
              >
                   <DatePicker popupClassName='auf-datepicker-popup' 
                   className='auf-datepicker' onChange={onChange} />
              <DatePicker popupClassName='auf-datepicker-popup' 
              className='auf-datepicker' onChange={onChange} picker="week" />
              <DatePicker popupClassName='auf-datepicker-popup' 
              className='auf-datepicker' onChange={onChange} picker="month" />
              <DatePicker popupClassName='auf-datepicker-popup' 
              className='auf-datepicker' onChange={onChange} picker="quarter" />
              <DatePicker popupClassName='auf-datepicker-popup' 
              className='auf-datepicker' onChange={onChange} picker="year" />
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-datepicker{
            background-color: var(--theme-bg-primary);
            border-color: $gray;
            &:hover{
                border-color: var(--theme-nav-link-primary);
            }
            .anticon.anticon-calendar
            {
                color: var(--theme-bg-reverse);
            }
            .ant-picker-input{
        input{
            color: var(--theme-bg-reverse);
            &::placeholder{
                color: var(--theme-bg-reverse);
            }
        }
            }
        
            &.ant-picker-focused{
                border-color: var(--theme-nav-link-primary);
                box-shadow: none;
            }
            .ant-picker-clear{
                background-color: var(--theme-bg-primary);
            }
            .ant-picker-active-bar{
                background: var(--theme-nav-link-primary);
            }
        }
        
        .auf-datepicker-popup{
            .ant-picker-panel{
                background-color: var(--theme-bg-primary) !important;
                border-color: $gray;
            }
            .ant-picker-header{
                color: var(--theme-bg-reverse);
                border-color: $gray;
        
            }
            .ant-picker-month-btn,.ant-picker-year-btn,.ant-picker-decade-btn{
                &:hover{
                    color: var(--theme-nav-link-primary) !important;
                }
            }
            .ant-picker-header-super-prev-btn,.ant-picker-header-prev-btn,.ant-picker-header-next-btn,
            .ant-picker-header-super-next-btn{
                color: var(--theme-bg-reverse) !important;
                &:hover{
                    color: var(--theme-nav-link-primary) !important;
                }
            }
            .ant-picker-content{
                thead{
                    tr{
                        th{
                            color: var(--theme-bg-reverse);
                        }
                    }
                }
                tbody{
                    tr{
                        .ant-picker-cell.ant-picker-cell-in-view{
                            color: var(--theme-bg-reverse);
                        }
                        .ant-picker-cell{
                            color: $gray;
                        }
                        td{
                            .ant-picker-cell-inner{
                                &:hover{
                                    background-color: $gray !important;
                                }
                            }
                        }
                        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today{
                            .ant-picker-cell-inner{
        
                        &::before{
                            border-color: var(--theme-nav-link-primary);
                        }
                            }
                        }
                        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected{
                            .ant-picker-cell-inner{
                                background-color: var(--theme-nav-link-primary) !important;
                            }
                        }
                        .ant-picker-cell-range-start{
                            .ant-picker-cell-inner{
                                background-color: var(--theme-nav-link-primary) !important;
                            }
                        }
                        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range,.ant-picker-cell-in-view
                        .ant-picker-cell-range-start,.ant-picker-cell-in-view.ant-picker-cell-range-end{
        &::before{
            background-color: $gray !important;
        }
                        }
                    }
                }
            }
            .ant-picker-today-btn{
                color: var(--theme-nav-link-primary);
                &:hover{
                    color: var(--theme-comp-hover);
                }
            }
            .ant-picker-week-panel-row.ant-picker-week-panel-row-selected{
                .ant-picker-cell{
                    &::before{
                        background: var(--theme-nav-link-primary) !important;
                    }
                }
            }
            .ant-picker-week-panel-row{
        &:hover{
            .ant-picker-cell{
                &::before{
                    background: $gray;
                }
            }
        }
            }
        .ant-picker-cell-end.ant-picker-cell-in-view.ant-picker-cell-range-end,.ant-picker-cell
        .ant-picker-cell-in-view.ant-picker-cell-range-end{
            .ant-picker-cell-inner{
                background-color: var(--theme-nav-link-primary) !important;
            }
        }
        .ant-picker-cell-in-view.ant-picker-cell-in-range{
            &:hover{
                &::before{
                    background-color: red !important;
                }
            }
        }
        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end-single.ant-picker-cell-selected,
        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-start-single{
            &::before{
                background-color: transparent !important;
            }
        }
        }`
        }
      ]
    },
    {
      key:2,
      comp:"range datepicker",
      code:[
        {
          tab:'js',
          code:`import React from 'react'
          import { DatePicker, Space } from 'antd';
          const { RangePicker } = DatePicker;
          function App() {
            return (
              <div
              style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'10px'
              }}
              >
                   <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker'/>
              <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker' showTime />
              <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker'picker="week" />
              <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker'picker="month" />
              <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker'picker="quarter" />
              <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker'picker="year" />
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import React from 'react'
          import { DatePicker, Space } from 'antd';
          const { RangePicker } = DatePicker;
          const App: React.FC = () => (
            return (
              <div
              style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'10px'
              }}
              >
                   <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker'/>
              <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker' showTime />
              <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker'picker="week" />
              <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker'picker="month" />
              <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker'picker="quarter" />
              <RangePicker popupClassName='auf-datepicker-popup' className='auf-datepicker'picker="year" />
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-datepicker{
            background-color: var(--theme-bg-primary);
            border-color: $gray;
            &:hover{
                border-color: var(--theme-nav-link-primary);
            }
            .anticon.anticon-calendar
            {
                color: var(--theme-bg-reverse);
            }
            .ant-picker-input{
        input{
            color: var(--theme-bg-reverse);
            &::placeholder{
                color: var(--theme-bg-reverse);
            }
        }
            }
        
            &.ant-picker-focused{
                border-color: var(--theme-nav-link-primary);
                box-shadow: none;
            }
            .ant-picker-clear{
                background-color: var(--theme-bg-primary);
            }
            .ant-picker-active-bar{
                background: var(--theme-nav-link-primary);
            }
        }
        
        .auf-datepicker-popup{
            .ant-picker-panel{
                background-color: var(--theme-bg-primary) !important;
                border-color: $gray;
            }
            .ant-picker-header{
                color: var(--theme-bg-reverse);
                border-color: $gray;
        
            }
            .ant-picker-month-btn,.ant-picker-year-btn,.ant-picker-decade-btn{
                &:hover{
                    color: var(--theme-nav-link-primary) !important;
                }
            }
            .ant-picker-header-super-prev-btn,.ant-picker-header-prev-btn,.ant-picker-header-next-btn,
            .ant-picker-header-super-next-btn{
                color: var(--theme-bg-reverse) !important;
                &:hover{
                    color: var(--theme-nav-link-primary) !important;
                }
            }
            .ant-picker-content{
                thead{
                    tr{
                        th{
                            color: var(--theme-bg-reverse);
                        }
                    }
                }
                tbody{
                    tr{
                        .ant-picker-cell.ant-picker-cell-in-view{
                            color: var(--theme-bg-reverse);
                        }
                        .ant-picker-cell{
                            color: $gray;
                        }
                        td{
                            .ant-picker-cell-inner{
                                &:hover{
                                    background-color: $gray !important;
                                }
                            }
                        }
                        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today{
                            .ant-picker-cell-inner{
        
                        &::before{
                            border-color: var(--theme-nav-link-primary);
                        }
                            }
                        }
                        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected{
                            .ant-picker-cell-inner{
                                background-color: var(--theme-nav-link-primary) !important;
                            }
                        }
                        .ant-picker-cell-range-start{
                            .ant-picker-cell-inner{
                                background-color: var(--theme-nav-link-primary) !important;
                            }
                        }
                        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range,.ant-picker-cell-in-view
                        .ant-picker-cell-range-start,.ant-picker-cell-in-view.ant-picker-cell-range-end{
        &::before{
            background-color: $gray !important;
        }
                        }
                    }
                }
            }
            .ant-picker-today-btn{
                color: var(--theme-nav-link-primary);
                &:hover{
                    color: var(--theme-comp-hover);
                }
            }
            .ant-picker-week-panel-row.ant-picker-week-panel-row-selected{
                .ant-picker-cell{
                    &::before{
                        background: var(--theme-nav-link-primary) !important;
                    }
                }
            }
            .ant-picker-week-panel-row{
        &:hover{
            .ant-picker-cell{
                &::before{
                    background: $gray;
                }
            }
        }
            }
        .ant-picker-cell-end.ant-picker-cell-in-view.ant-picker-cell-range-end,.ant-picker-cell
        .ant-picker-cell-in-view.ant-picker-cell-range-end{
            .ant-picker-cell-inner{
                background-color: var(--theme-nav-link-primary) !important;
            }
        }
        .ant-picker-cell-in-view.ant-picker-cell-in-range{
            &:hover{
                &::before{
                    background-color: red !important;
                }
            }
        }
        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end-single.ant-picker-cell-selected,
        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-start-single{
            &::before{
                background-color: transparent !important;
            }
        }
        }`
        }
      ]
    },
    {
      key:3,
      comp:"choose time",
      code:[
        {
          tab:'js',
          code:`import React from 'react'
          import { DatePicker, Space } from 'antd';
          const { RangePicker } = DatePicker;
          const onChange = (value, dateString) => {
            console.log('Selected Time: ', value);
            console.log('Formatted Selected Time: ', dateString);
          };
          const onOk = (value) => {
            console.log('onOk: ', value);
          };
          function App() {
            return (
              <div
              style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'10px'
              }}
              >
                  <DatePicker popupClassName='auf-datepicker-popup' className='auf-datepicker' showTime onChange={onChange} onOk={onOk} />
              <RangePicker
              popupClassName='auf-datepicker-popup' className='auf-datepicker'
                showTime={{
                  format: 'HH:mm',
                }}
                format="YYYY-MM-DD HH:mm"
                onChange={onChange}
                onOk={onOk}
              />
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import React from 'react'
          import { DatePicker, Space } from 'antd';
          type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

          const { RangePicker } = DatePicker;

          const onChange = (
            value: DatePickerProps['value'] | RangePickerProps['value'],
            dateString: [string, string] | string,
          ) => {
            console.log('Selected Time: ', value);
            console.log('Formatted Selected Time: ', dateString);
          };
          const onOk = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
            console.log('onOk: ', value);
          };
          const App: React.FC = () => (
            return (
              <div
              style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'10px'
              }}
              >
                  <DatePicker popupClassName='auf-datepicker-popup' className='auf-datepicker' showTime onChange={onChange} onOk={onOk} />
              <RangePicker
              popupClassName='auf-datepicker-popup' className='auf-datepicker'
                showTime={{
                  format: 'HH:mm',
                }}
                format="YYYY-MM-DD HH:mm"
                onChange={onChange}
                onOk={onOk}
              />
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-datepicker{
            background-color: var(--theme-bg-primary);
            border-color: $gray;
            &:hover{
                border-color: var(--theme-nav-link-primary);
            }
            .anticon.anticon-calendar
            {
                color: var(--theme-bg-reverse);
            }
            .ant-picker-input{
        input{
            color: var(--theme-bg-reverse);
            &::placeholder{
                color: var(--theme-bg-reverse);
            }
        }
            }
        
            &.ant-picker-focused{
                border-color: var(--theme-nav-link-primary);
                box-shadow: none;
            }
            .ant-picker-clear{
                background-color: var(--theme-bg-primary);
            }
            .ant-picker-active-bar{
                background: var(--theme-nav-link-primary);
            }
        }
        
        .auf-datepicker-popup{
            .ant-picker-panel{
                background-color: var(--theme-bg-primary) !important;
                border-color: $gray;
            }
            .ant-picker-header{
                color: var(--theme-bg-reverse);
                border-color: $gray;
        
            }
            .ant-picker-month-btn,.ant-picker-year-btn,.ant-picker-decade-btn{
                &:hover{
                    color: var(--theme-nav-link-primary) !important;
                }
            }
            .ant-picker-header-super-prev-btn,.ant-picker-header-prev-btn,.ant-picker-header-next-btn,
            .ant-picker-header-super-next-btn{
                color: var(--theme-bg-reverse) !important;
                &:hover{
                    color: var(--theme-nav-link-primary) !important;
                }
            }
            .ant-picker-content{
                thead{
                    tr{
                        th{
                            color: var(--theme-bg-reverse);
                        }
                    }
                }
                tbody{
                    tr{
                        .ant-picker-cell.ant-picker-cell-in-view{
                            color: var(--theme-bg-reverse);
                        }
                        .ant-picker-cell{
                            color: $gray;
                        }
                        td{
                            .ant-picker-cell-inner{
                                &:hover{
                                    background-color: $gray !important;
                                }
                            }
                        }
                        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today{
                            .ant-picker-cell-inner{
        
                        &::before{
                            border-color: var(--theme-nav-link-primary);
                        }
                            }
                        }
                        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected{
                            .ant-picker-cell-inner{
                                background-color: var(--theme-nav-link-primary) !important;
                            }
                        }
                        .ant-picker-cell-range-start{
                            .ant-picker-cell-inner{
                                background-color: var(--theme-nav-link-primary) !important;
                            }
                        }
                        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range,.ant-picker-cell-in-view
                        .ant-picker-cell-range-start,.ant-picker-cell-in-view.ant-picker-cell-range-end{
        &::before{
            background-color: $gray !important;
        }
                        }
                    }
                }
            }
            .ant-picker-today-btn{
                color: var(--theme-nav-link-primary);
                &:hover{
                    color: var(--theme-comp-hover);
                }
            }
            .ant-picker-week-panel-row.ant-picker-week-panel-row-selected{
                .ant-picker-cell{
                    &::before{
                        background: var(--theme-nav-link-primary) !important;
                    }
                }
            }
            .ant-picker-week-panel-row{
        &:hover{
            .ant-picker-cell{
                &::before{
                    background: $gray;
                }
            }
        }
            }
        .ant-picker-cell-end.ant-picker-cell-in-view.ant-picker-cell-range-end,.ant-picker-cell
        .ant-picker-cell-in-view.ant-picker-cell-range-end{
            .ant-picker-cell-inner{
                background-color: var(--theme-nav-link-primary) !important;
            }
        }
        .ant-picker-cell-in-view.ant-picker-cell-in-range{
            &:hover{
                &::before{
                    background-color: red !important;
                }
            }
        }
        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end-single.ant-picker-cell-selected,
        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-start-single{
            &::before{
                background-color: transparent !important;
            }
        }
        }`
        }
      ]
    },
  ]
  return (
    <Layout title='date picker'>
    <CompLayout title={"basic date picker"} code={code[0]}>
    <BasicDatePicker />
    </CompLayout>
    <CompLayout title={"range picker"} code={code[1]}>
<RangePickerComp />
    </CompLayout>
    <CompLayout title={"choose time"} code={code[2]}>
<ChooseTime />
    </CompLayout>
    </Layout>
  )
}

export default DatePicker