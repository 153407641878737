import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import Basic from '../Components/Elements/Toggle/Basic'
import SmallToggle from '../Components/Elements/Toggle/SmallToggle'
import ToggleWithLabel from '../Components/Elements/Toggle/ToggleWithLabel'
import DisabledToggle from '../Components/Elements/Toggle/DisabledToggle'
import LoadingToggle from '../Components/Elements/Toggle/LoadingToggle'

function Toggle() {
  const code = [
    {
      key: 1,
      comp: "basic toggle",
      code: [
        {
          tab: 'js',
          code: `import React from 'react'
          import { Switch } from 'antd';
          function App() {
              const onChange = (checked) => {
                  console.log('switch to', checked);
                };
            return (
              <Switch className='auf-toggle' defaultChecked onChange={onChange} />
            )
          }
          
          export default App
          `
        },
        {
tab:'ts',
code:`import React from 'react';
import { Switch } from 'antd';

const onChange = (checked: boolean) => {
  console.log("switch to" ,checked);
};

const App: React.FC = () => <Switch className='auf-toggle' defaultChecked onChange={onChange} />;

export default App;
`
        },
        {
          tab: 'scss',
          code: `.auf-toggle.ant-switch{
            background-color: var(--theme-nav-link-secondary);
            &.ant-switch-checked{
                background-color: var(--theme-nav-link-primary);
            
                &:hover{
                    background-color: var(--theme-comp-hover) ;
                }
            }
                .ant-switch-handle{
            &::before{
              background-color: $white;
            }
                }
            }`
        },
      ]
    },
    {
      key: 2,
      comp: "disabled toggle",
      code: [
        {
          tab: 'js',
          code: `import { Switch } from 'antd'
          import React from 'react'
          
          function App() {
            return (
              <Switch className='auf-toggle' disabled={true} defaultChecked />
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import React from 'react';
          import { Switch } from 'antd';
          
          const App: React.FC = () => <Switch className='auf-toggle' disabled={true} defaultChecked />;
          
          export default App;
          `
                  },
        {
          tab: 'scss',
          code: `.auf-toggle.ant-switch{
            background-color: var(--theme-nav-link-secondary);
            &.ant-switch-checked{
                background-color: var(--theme-nav-link-primary);
            
                &:hover{
                    background-color: var(--theme-comp-hover) ;
                }
            }
                .ant-switch-handle{
            &::before{
              background-color: $white;
            }
                }
            }`
        },
      ]
    },
    {
      key: 3,
      comp: "small toggle",
      code: [
        {
          tab: 'js',
          code: `import { Switch } from 'antd'
          import React from 'react'
          
          function App() {
            return (
              <Switch className='auf-toggle' size="small" defaultChecked />
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import React from 'react';
          import { Switch } from 'antd';
          
          const App: React.FC = () => <Switch className='auf-toggle' size="small" defaultChecked />;
          
          export default App;
          `
                  },
        {
          tab: 'scss',
          code: `.auf-toggle.ant-switch{
            background-color: var(--theme-nav-link-secondary);
            &.ant-switch-checked{
                background-color: var(--theme-nav-link-primary);
            
                &:hover{
                    background-color: var(--theme-comp-hover) ;
                }
            }
                .ant-switch-handle{
            &::before{
              background-color: $white;
            }
                }
            }`
        },
      ]
    },
    {
      key: 4,
      comp: "icon toggle",
      code: [
        {
          tab: 'js',
          code: `import { Switch } from 'antd'
          import React from 'react'
          
          function App() {
            return (
              <Switch className='auf-toggle' checkedChildren="1" unCheckedChildren="0" />
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import React from 'react';
          import { Switch } from 'antd';
        
          const App: React.FC = () =>  <Switch className='auf-toggle' checkedChildren="1" unCheckedChildren="0" />;
          
          export default App;
          `
                  },
        {
          tab: 'scss',
          code: `.auf-toggle.ant-switch{
            background-color: var(--theme-nav-link-secondary);
            &.ant-switch-checked{
                background-color: var(--theme-nav-link-primary);
            
                &:hover{
                    background-color: var(--theme-comp-hover) ;
                }
            }
                .ant-switch-handle{
            &::before{
              background-color: $white;
            }
                }
                .ant-switch-inner-checked{
                    margin-top: 3px;
                }
                .ant-switch-inner-unchecked{
                    margin-top: -15px;
                }
            }`
        },
      ]
    },
    {
      key: 5,
      comp: "loading toggle",
      code: [
        {
          tab: 'js',
          code: `import { Switch } from 'antd'
          import React from 'react'
          
          function App() {
            return (
              <Switch className='auf-toggle' loading defaultChecked />
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import React from 'react';
          import { Switch } from 'antd';
          
          const App: React.FC = () => <Switch className='auf-toggle' loading defaultChecked />;
          
          export default App;
          `
                  },
        {
          tab: 'scss',
          code: `.auf-toggle.ant-switch{
            background-color: var(--theme-nav-link-secondary);
            &.ant-switch-checked{
                background-color: var(--theme-nav-link-primary);
            
                &:hover{
                    background-color: var(--theme-comp-hover) ;
                }
            }
                .ant-switch-handle{
            &::before{
              background-color: $white;
            }
                }
            }`
        },
      ]
    },
  ]
  return (
    <Layout title='toggle'>
        <CompLayout title={"Basic Toggle"} code={code[0]}>
<Basic />
      </CompLayout>
        <CompLayout title={"Disabled Toggle"} code={code[1]}>
<DisabledToggle />
      </CompLayout>
        <CompLayout title={"Small Toggle"} code={code[2]}>
<SmallToggle />
      </CompLayout>
        <CompLayout title={"Toggle with Icon"} code={code[3]}>
<ToggleWithLabel />
      </CompLayout>
        <CompLayout title={"Loading Toggle"} code={code[4]}>
<LoadingToggle />
      </CompLayout>
    </Layout>
  )
}

export default Toggle