import { Card } from 'antd'
import React from 'react'

function ContentCard() {
  return (
    <div>
        <Card
      title="Default size card"
      className='auf-card'
      extra={<a href="#">More</a>}
      style={{
        width: 300,
      }}
    >
      <p>Card content</p>
      <p>Card content</p>
      <p>Card content</p>
    </Card>
    <br />
    <Card
     className='auf-card'
      size="small"
      title="Small size card"
      extra={<a href="#">More</a>}
      style={{
        width: 300,
      }}
    >
      <p>Card content</p>
      <p>Card content</p>
      <p>Card content</p>
    </Card>
    </div>
  )
}

export default ContentCard