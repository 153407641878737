import { Radio } from 'antd'
import React, { useState } from 'react'

function RadioGroup() {
    const [value, setValue] = useState(1);
    const onChange = (e) => {
      console.log('radio checked', e.target.value);
      setValue(e.target.value);
    };
  return (
    <Radio.Group value={value} onChange={onChange}>
    <Radio className='auf-radio' value={1}>A</Radio>
    <Radio className='auf-radio' value={2}>B</Radio>
    <Radio className='auf-radio' value={3}>C</Radio>
    <Radio className='auf-radio' value={4}>D</Radio>
  </Radio.Group>
  )
}

export default RadioGroup