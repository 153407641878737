import React from "react";
import { Button } from "antd";
function PrimaryButton({ children, onClick }) {
  return (
    <Button type="primary" onClick={onClick} className="auf-button">
      {children}
    </Button>
  );
}

export default PrimaryButton;
