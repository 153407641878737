import { Tooltip } from 'antd'
import React from 'react'

function BasicTooltip() {
  return (
    <Tooltip overlayClassName="auf-tooltip-overlay" className='auf-tooltip' title="This is a text">
    <span>Tooltip will show on mouse enter.</span>
  </Tooltip>
  )
}

export default BasicTooltip