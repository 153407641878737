import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import BasicTag from '../Components/Elements/Tag/BasicTag'

function Tag() {
  const code = [
    {
      key:1,
      comp:"Basic tag",
      code:[
        {
          tab:'js',
          code:`import { Tag } from 'antd'
          import React from 'react'
          import { CloseCircleOutlined } from '@ant-design/icons';
          function App() {
              const log = (e) => {
                  console.log(e);
                };
                const preventDefault = (e) => {
                  e.preventDefault();
                  console.log('Clicked! But prevent default.');
                };
            return (
              <div style={{
                  display:'flex',
                  height:'fit-content'
              }}>
                   <Tag className='auf-tag'>Tag 1</Tag>
              <Tag className='auf-tag'>
                <a href="https://github.com/ant-design/ant-design/issues/1862">Link</a>
              </Tag>
              <Tag className='auf-tag' closeIcon onClose={preventDefault}>
                Prevent Default
              </Tag>
              <Tag className='auf-tag' closeIcon={<CloseCircleOutlined />} onClose={log}>
                Tag 2
              </Tag>
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import { Tag } from 'antd'
          import React from 'react'
          import { CloseCircleOutlined } from '@ant-design/icons';
          const App: React.FC = () => (
            const log = (e: React.MouseEvent<HTMLElement>) => {
              console.log(e);
            };
            const preventDefault = (e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              console.log('Clicked! But prevent default.');
            };
            return (
              <div style={{
                  display:'flex',
                  height:'fit-content'
              }}>
                   <Tag className='auf-tag'>Tag 1</Tag>
              <Tag className='auf-tag'>
                <a href="https://github.com/ant-design/ant-design/issues/1862">Link</a>
              </Tag>
              <Tag className='auf-tag' closeIcon onClose={preventDefault}>
                Prevent Default
              </Tag>
              <Tag className='auf-tag' closeIcon={<CloseCircleOutlined />} onClose={log}>
                Tag 2
              </Tag>
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-tag{
            display: flex;
            align-items: center;
            background-color: var(--theme-bg-primary);
            color: var(--theme-bg-reverse);
            border-color: $gray;
            font-size: 13px;
            a{
            font-size: 13px;
            color: var(--theme-bg-reverse);
        
            &:hover{
                color: var(--theme-nav-link-primary);
            }
            }
            .anticon{
                color:var(--theme-bg-reverse);
        
                &:hover{
                    color: var(--theme-nav-link-primary);
                }
            }
        }`
        }
      ]
    }
  ]
  return (
    <Layout title='tag'>
    <CompLayout title={"Basic Tag"} code={code[0]}>
    <BasicTag />
    </CompLayout>
    </Layout>
  )
}

export default Tag