import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import ContentCard from '../Components/Elements/Card/ContentCard'
import ImageCard from '../Components/Elements/Card/ImageCard'

function Card() {
  const code = [
    {
      key:1,
      comp:"data card",
      code:[
        {
          tab:'js',
          code:`import { Card } from 'antd'
          import React from 'react'
          
          function App() {
            return (
              <div>
                  <Card
                title="Default size card"
                className='auf-card'
                extra={<a href="#">More</a>}
                style={{
                  width: 300,
                }}
              >
                <p>Card content</p>
                <p>Card content</p>
                <p>Card content</p>
              </Card>
              <br />
              <Card
               className='auf-card'
                size="small"
                title="Small size card"
                extra={<a href="#">More</a>}
                style={{
                  width: 300,
                }}
              >
                <p>Card content</p>
                <p>Card content</p>
                <p>Card content</p>
              </Card>
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import { Card } from 'antd'
          import React from 'react'
          
          const App: React.FC = () => (
            return (
              <div>
                  <Card
                title="Default size card"
                className='auf-card'
                extra={<a href="#">More</a>}
                style={{
                  width: 300,
                }}
              >
                <p>Card content</p>
                <p>Card content</p>
                <p>Card content</p>
              </Card>
              <br />
              <Card
               className='auf-card'
                size="small"
                title="Small size card"
                extra={<a href="#">More</a>}
                style={{
                  width: 300,
                }}
              >
                <p>Card content</p>
                <p>Card content</p>
                <p>Card content</p>
              </Card>
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-card{
            border-color: $gray;
            color: var(--theme-bg-reverse);
            background-color: var(--theme-bg-primary);
            &:hover{
                box-shadow: none;
                border-color: $gray;
            }
            .ant-card-head{
                border-color: $gray;
            }
            .ant-card-head-title{
                color: var(--theme-bg-reverse);
            }
            .ant-card-body{
                max-height: 450px;
                overflow-y: auto;
            }
            .ant-card-extra{
               a{
                color: var(--theme-nav-link-primary);
        
                &:hover{
                    color: var( --theme-comp-hover);
                }
               }
            }
            .ant-card-meta-title,.ant-card-meta-description{
                color: var(--theme-bg-reverse);
            }
        }`
        }
      ]
    },
    {
      key:2,
      comp:"data card",
      code:[
        {
          tab:'js',
          code:`import { Card } from 'antd'
          import React from 'react'
          
          function App() {
              const { Meta } = Card;
            return (
              <Card
               className='auf-card'
              hoverable
              style={{
                width: 240,
              }}
              cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
            >
              <Meta title="Europe Street beat" description="www.instagram.com" />
            </Card>
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import { Card } from 'antd'
          import React from 'react'
          
          const App: React.FC = () => (
              const { Meta } = Card;
            return (
              <Card
               className='auf-card'
              hoverable
              style={{
                width: 240,
              }}
              cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
            >
              <Meta title="Europe Street beat" description="www.instagram.com" />
            </Card>
            )
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-card{
            border-color: $gray;
            color: var(--theme-bg-reverse);
            background-color: var(--theme-bg-primary);
            &:hover{
                box-shadow: none;
                border-color: $gray;
            }
            .ant-card-head{
                border-color: $gray;
            }
            .ant-card-head-title{
                color: var(--theme-bg-reverse);
            }
            .ant-card-body{
                max-height: 450px;
                overflow-y: auto;
            }
            .ant-card-extra{
               a{
                color: var(--theme-nav-link-primary);
        
                &:hover{
                    color: var( --theme-comp-hover);
                }
               }
            }
            .ant-card-meta-title,.ant-card-meta-description{
                color: var(--theme-bg-reverse);
            }
        }`
        }
      ]
    },
  ]
  return (
    <Layout title='card'>
    <CompLayout title={"Content card"} code={code[0]}>
<ContentCard />
    </CompLayout>
    <CompLayout title={"image card"} code={code[1]}>
<ImageCard />
    </CompLayout>
    </Layout>
  )
}

export default Card