import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import BasicCheckbox from '../Components/Elements/Checkbox/BasicCheckbox'
import DisabledCheckbox from '../Components/Elements/Checkbox/DisabledCheckbox'
import CheckboxGroup from '../Components/Elements/Checkbox/CheckboxGroup'
import CheckAll from '../Components/Elements/Checkbox/CheckAll'

function Checkbox() {
  const code = [
    {
      key: 1,
      comp: "basic checkbox",
      code: [
        {
          tab: 'js',
          code: `import { Checkbox } from 'antd'
          import React from 'react'
          
          function App() {
            return (
              <Checkbox className='auf-checkbox'>Checkbox</Checkbox>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import { Checkbox } from 'antd'
          import React from 'react'
          
          const App: React.FC = () => {
            return (
              <Checkbox className='auf-checkbox'>Checkbox</Checkbox>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'scss',
          code: `.auf-checkbox,.ant-checkbox-wrapper{
            display: flex;
            align-items: flex-start;
            height: fit-content;
             .ant-checkbox-checked{
                 .ant-checkbox-inner{
                    background-color: var(--theme-nav-link-primary);
                    border-color: var(--theme-nav-link-primary);
                 }
             }
             .ant-checkbox-inner{
         background-color: var(--theme-bg-primary);
         border-color: var(--theme-bg-reverse);
            }
          span{
            color: var(--theme-bg-reverse);
          }   
         .ant-checkbox-indeterminate{
            .ant-checkbox-inner{
               &::after{
         background-color: var(--theme-nav-link-primary);
               }
            }
         }
         
         }
         
         .ant-checkbox-wrapper{
            &:hover{
               .ant-checkbox-inner{
                  border-color: var(--theme-nav-link-primary) !important;
               }
               .ant-checkbox-checked{
                  .ant-checkbox-inner{
                     background-color: var(--theme-comp-hover) !important;
                  }
               }
             }
         }
         `
        },
      ]
    },
    {
      key: 2,
      comp: "disabled checkbox",
      code: [
        {
          tab: 'js',
          code: `import { Checkbox } from 'antd'
          import React from 'react'
          
          function App() {
            return (
              <div style={{
                  display:'flex',
                  gap:"10px"
              }}>
              <Checkbox className='auf-checkbox' defaultChecked={false} disabled />
              <Checkbox className='auf-checkbox' indeterminate disabled />
              <Checkbox className='auf-checkbox' defaultChecked disabled />
            </div>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import { Checkbox } from 'antd'
          import React from 'react'
          
          const App: React.FC = () => {
            return (
              <div style={{
                  display:'flex',
                  gap:"10px"
              }}>
              <Checkbox className='auf-checkbox' defaultChecked={false} disabled />
              <Checkbox className='auf-checkbox' indeterminate disabled />
              <Checkbox className='auf-checkbox' defaultChecked disabled />
            </div>
            )
          }
          
          export default App
          `
        },
        {
          tab: 'scss',
          code: `.auf-checkbox,.ant-checkbox-wrapper{
            display: flex;
            align-items: flex-start;
            height: fit-content;
             .ant-checkbox-checked{
                 .ant-checkbox-inner{
                    background-color: var(--theme-nav-link-primary);
                    border-color: var(--theme-nav-link-primary);
                 }
             }
             .ant-checkbox-inner{
         background-color: var(--theme-bg-primary);
         border-color: var(--theme-bg-reverse);
            }
          span{
            color: var(--theme-bg-reverse);
          }   
         .ant-checkbox-indeterminate{
            .ant-checkbox-inner{
               &::after{
         background-color: var(--theme-nav-link-primary);
               }
            }
         }
         
         }
         
         .ant-checkbox-wrapper{
            &:hover{
               .ant-checkbox-inner{
                  border-color: var(--theme-nav-link-primary) !important;
               }
               .ant-checkbox-checked{
                  .ant-checkbox-inner{
                     background-color: var(--theme-comp-hover) !important;
                  }
               }
             }
         }
         `
        },
      ]
    },
    {
      key: 3,
      comp: "checkbox group",
      code: [
        {
          tab: 'js',
          code: `import { Checkbox } from 'antd';
          import React from 'react'
          
          function App() {
              const plainOptions = ['Apple', 'Pear', 'Orange'];
            return (
              <Checkbox.Group className='auf-checkbox' options={plainOptions} defaultValue={['Apple']}  />
            )
          }
          
          export default App
          `
        },
        {
          tab: 'ts',
          code: `import { Checkbox } from 'antd';
          import React from 'react'
          
          const App: React.FC = () =>{
              const plainOptions = ['Apple', 'Pear', 'Orange'];
            return (
              <Checkbox.Group className='auf-checkbox' options={plainOptions} defaultValue={['Apple']}  />
            )
          }
          
          export default App
          `
        },
        {
          tab: 'scss',
          code: `.auf-checkbox,.ant-checkbox-wrapper{
            display: flex;
            align-items: flex-start;
            height: fit-content;
             .ant-checkbox-checked{
                 .ant-checkbox-inner{
                    background-color: var(--theme-nav-link-primary);
                    border-color: var(--theme-nav-link-primary);
                 }
             }
             .ant-checkbox-inner{
         background-color: var(--theme-bg-primary);
         border-color: var(--theme-bg-reverse);
            }
          span{
            color: var(--theme-bg-reverse);
          }   
         .ant-checkbox-indeterminate{
            .ant-checkbox-inner{
               &::after{
         background-color: var(--theme-nav-link-primary);
               }
            }
         }
         
         }
         
         .ant-checkbox-wrapper{
            &:hover{
               .ant-checkbox-inner{
                  border-color: var(--theme-nav-link-primary) !important;
               }
               .ant-checkbox-checked{
                  .ant-checkbox-inner{
                     background-color: var(--theme-comp-hover) !important;
                  }
               }
             }
         }
         `
        },
      ]
    },
    {
      key: 4,
      comp: "checkall",
      code: [
        {
          tab: 'js',
          code: `import { Checkbox } from 'antd';
          import React, { useState } from 'react'
          
          function App() {
              const CheckboxGroup = Checkbox.Group;
              const plainOptions = ['Apple', 'Pear', 'Orange'];
              const defaultCheckedList = ['Apple', 'Orange'];
              const [checkedList, setCheckedList] = useState(defaultCheckedList);
            const App = plainOptions.length === checkedList.length;
            const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
            const onChange = (list) => {
              setCheckedList(list);
            };
            const onCheckAllChange = (e) => {
              setCheckedList(e.target.checked ? plainOptions : []);
            };
          
            return (
              <div 
              style={{
                  display:"flex",
                  flexDirection:"column",
                  gap:"20px"
              }}
              >
              <Checkbox  className='auf-checkbox'indeterminate={indeterminate} 
              onChange={onCheckAllChange} checked={checkAll}>
                Check all
              </Checkbox>
              <CheckboxGroup className='auf-checkbox' options={plainOptions} 
              value={checkedList} onChange={onChange} />
            </div>
            )
          }
          
          export default CheckAll
          `
        },
        {
          tab: 'ts',
          code: `import { Checkbox } from 'antd';
          import React, { useState } from 'react'
          
          const App: React.FC = () =>{
            const CheckboxGroup = Checkbox.Group;

            const plainOptions = ['Apple', 'Pear', 'Orange'];
            const defaultCheckedList = ['Apple', 'Orange'];
            
            const App: React.FC = () => {
              const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);
            
              const checkAll = plainOptions.length === checkedList.length;
              const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
            
              const onChange = (list: CheckboxValueType[]) => {
                setCheckedList(list);
              };
            
              const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
                setCheckedList(e.target.checked ? plainOptions : []);
              };
          
            return (
              <div 
              style={{
                  display:"flex",
                  flexDirection:"column",
                  gap:"20px"
              }}
              >
              <Checkbox  className='auf-checkbox'indeterminate={indeterminate} 
              onChange={onCheckAllChange} checked={checkAll}>
                Check all
              </Checkbox>
              <CheckboxGroup className='auf-checkbox' options={plainOptions} 
              value={checkedList} onChange={onChange} />
            </div>
            )
          }
          
          export default CheckAll
          `
        },
        {
          tab: 'scss',
          code: `.auf-checkbox,.ant-checkbox-wrapper{
            display: flex;
            align-items: flex-start;
            height: fit-content;
             .ant-checkbox-checked{
                 .ant-checkbox-inner{
                    background-color: var(--theme-nav-link-primary);
                    border-color: var(--theme-nav-link-primary);
                 }
             }
             .ant-checkbox-inner{
         background-color: var(--theme-bg-primary);
         border-color: var(--theme-bg-reverse);
            }
          span{
            color: var(--theme-bg-reverse);
          }   
         .ant-checkbox-indeterminate{
            .ant-checkbox-inner{
               &::after{
         background-color: var(--theme-nav-link-primary);
               }
            }
         }
         
         }
         
         .ant-checkbox-wrapper{
            &:hover{
               .ant-checkbox-inner{
                  border-color: var(--theme-nav-link-primary) !important;
               }
               .ant-checkbox-checked{
                  .ant-checkbox-inner{
                     background-color: var(--theme-comp-hover) !important;
                  }
               }
             }
         }
         `
        },
      ]
    },
  ]
  return (
    <Layout title='checkbox'>
    <CompLayout title={"Basic Checkbox"} code={code[0]}>
<BasicCheckbox />
  </CompLayout>
    <CompLayout title={"Disabled Checkbox"} code={code[1]}>
<DisabledCheckbox />
  </CompLayout>
    <CompLayout title={"Checkbox Group"} code={code[2]}>
<CheckboxGroup />
  </CompLayout>
    <CompLayout title={"Check All"} code={code[3]}>
<CheckAll />
  </CompLayout>
  </Layout>
  )
}

export default Checkbox