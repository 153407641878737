import React from 'react'
import { Tabs } from 'antd';
function BasicTab() {
  const items = [
    {
      key: '1',
      label: 'Tab 1',
      children: 'Content of Tab Pane 1',
    },
    {
      key: '2',
      label: 'Tab 2',
      children: 'Content of Tab Pane 2',
    },
    {
      key: '3',
      label: 'Tab 3',
      children: 'Content of Tab Pane 3',
    },
  ];
    const onChange = (key) => {
      // console.log(key);
    };
  return (
    <div style={{
      width:'100%'
    }}>
      <Tabs className='auf-tabs' defaultActiveKey="1" items={items} onChange={onChange}/>
    </div>
  )
}

export default BasicTab