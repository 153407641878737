import React from 'react'
import { Button } from "antd";
import { SearchOutlined } from '@ant-design/icons';
function ButtonWithIcon({children,onClick}) {
  return (
    <Button
    icon={<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 20 20" fill="none">
    <circle cx="9.80492" cy="9.80492" r="7.49047" stroke="#17232A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.0156 15.4043L17.9523 18.3334" stroke="#17232A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>}
  onClick={onClick}
  className="auf-button"
  >
    {children}
  </Button>
  )
}

export default ButtonWithIcon