import { Select } from 'antd';
import React from 'react'

function MultiSelect() {
    const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}
const handleChange = (value) => {
  console.log(`selected ${value}`);
};
  return (
    <Select
    className='auf-select'
    popupClassName='auf-select-popup'
      mode="multiple"
      allowClear
      style={{
        width: '100%',
      }}
      placeholder="Please select"
      defaultValue={['a10', 'c12']}
      onChange={handleChange}
      options={options}
    />
  )
}

export default MultiSelect