import { Button, Tooltip } from 'antd'
import React, { useState } from 'react'

function DisabledTooltip() {
    const [disabled, setDisabled] = useState(true);
  return (
    <div
        style={{
        display:'flex',
        alignItems:"center",
        gap:"10px"
    }}
    >
         <Button className='auf-button' block={false} onClick={() => setDisabled(!disabled)}>{disabled ? 'Enable' : 'Disable'}</Button>
      <Tooltip overlayClassName="auf-tooltip-overlay" title={disabled ? '' : 'prompt text'}>
        <span>Tooltip will show on mouse enter.</span>
      </Tooltip>
    </div>
  )
}

export default DisabledTooltip