import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import BasicTab from '../Components/Elements/Tab/BasicTab'
import SlideTab from '../Components/Elements/Tab/SlideTab'

function Tab() {
  const code = [
    {
      key:1,
      comp:"scrollable table",
      code:[
        {
          tab:'js',
          code:`import React from 'react'
          import { Tabs } from 'antd';
          function App() {
            const items = [
              {
                key: '1',
                label: 'Tab 1',
                children: 'Content of Tab Pane 1',
              },
              {
                key: '2',
                label: 'Tab 2',
                children: 'Content of Tab Pane 2',
              },
              {
                key: '3',
                label: 'Tab 3',
                children: 'Content of Tab Pane 3',
              },
            ];
              const onChange = (key) => {
                // console.log(key);
              };
            return (
              <div style={{
                width:'100%'
              }}>
                <Tabs className='auf-tabs' defaultActiveKey="1" items={items} onChange={onChange}/>
              </div>
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import React from 'react';
          import { Tabs } from 'antd';
          import type { TabsProps } from 'antd';
          
          const onChange = (key: string) => {
            console.log(key);
          };
          
          const items: TabsProps['items'] = [
            {
              key: '1',
              label: 'Tab 1',
              children: 'Content of Tab Pane 1',
            },
            {
              key: '2',
              label: 'Tab 2',
              children: 'Content of Tab Pane 2',
            },
            {
              key: '3',
              label: 'Tab 3',
              children: 'Content of Tab Pane 3',
            },
          ];
          
          const App: React.FC = () => <Tabs defaultActiveKey="1" items={items} onChange={onChange} />;
          
          export default App;
          `
        },
        {
          tab:'scss',
          code:`.auf-tabs{
            .ant-tabs-nav{
                &::before{
                    border-color: var(--theme-bg-reverse);
                }
            }
            .ant-tabs-tab{
                .ant-tabs-tab-btn{
                    color: var(--theme-bg-reverse);
                    text-transform: capitalize;
                    &:hover{
                        color: var(--theme-nav-link-primary);
                    }
                }
                &.ant-tabs-tab-active{
                    .ant-tabs-tab-btn{
                    color: var(--theme-nav-link-primary);
                }
            }
            }
            .ant-tabs-ink-bar{
                background-color: var(--theme-nav-link-primary);
            }
            .ant-tabs-content{
                .ant-tabs-tabpane{
                    color: var(--theme-bg-reverse);
                }
            }
        }`
        }
      ]
    },
    {
      key:2,
      comp:"scrollable table",
      code:[
        {
          tab:'js',
          code:`import React, { useState } from 'react';
          import { Radio, Tabs } from 'antd';
          function SlideTab() {
            const [mode, setMode] = useState('top');
            const handleModeChange = (e) => {
              setMode(e.target.value);
            };
              return (
          <div>
                <Radio.Group
                className='auf-radio'
                  onChange={handleModeChange}
                  value={mode}
                  style={{
                    marginBottom: 8,
                  }}
                >
                  <Radio.Button  value="top">Horizontal</Radio.Button>
                  <Radio.Button value="left">Vertical</Radio.Button>
                </Radio.Group>
                <div style={{
                  width:'1000px'
                }}>
                <Tabs
                className='auf-tabs'
                  defaultActiveKey="1"
                  tabPosition={mode}
                  style={{
                    height: 220,
                  }}
                  items={new Array(30).fill(null).map((_, i) => {
                    const id = String(i);
                    return {
                      label: "Tab-",id,
                      key: id,
                      disabled: i === 28,
                      children: "Content of tab" ,id,
                    };
                  })}
                />
                </div>
              </div>
              );
          }
          
          export default SlideTab
          `
        },
        {
          tab:'ts',
          code:`import React, { useState } from 'react';
          import type { RadioChangeEvent } from 'antd';
          import { Radio, Tabs } from 'antd';
          
          type TabPosition = 'left' | 'right' | 'top' | 'bottom';
          
          const App: React.FC = () => {
            const [mode, setMode] = useState<TabPosition>('top');
          
            const handleModeChange = (e: RadioChangeEvent) => {
              setMode(e.target.value);
            };
          
            return (
              <div>
                <Radio.Group onChange={handleModeChange} value={mode} style={{ marginBottom: 8 }}>
                  <Radio.Button value="top">Horizontal</Radio.Button>
                  <Radio.Button value="left">Vertical</Radio.Button>
                </Radio.Group>
                <Tabs
                  defaultActiveKey="1"
                  tabPosition={mode}
                  style={{ height: 220 }}
                  items={new Array(30).fill(null).map((_, i) => {
                    const id = String(i);
                    return {
                      label: "Tab-",id,
                      key: id,
                      disabled: i === 28,
                      children: "Content of tab" ,id,
                    };
                  })}
                />
              </div>
            );
          };
          
          export default App;
          `
        },
        {
          tab:'scss',
          code:`.auf-tabs{
            .ant-tabs-nav{
                &::before{
                    border-color: var(--theme-bg-reverse);
                }
            }
            .ant-tabs-tab{
                .ant-tabs-tab-btn{
                    color: var(--theme-bg-reverse);
                    text-transform: capitalize;
                    &:hover{
                        color: var(--theme-nav-link-primary);
                    }
                }
                &.ant-tabs-tab-active{
                    .ant-tabs-tab-btn{
                    color: var(--theme-nav-link-primary);
                }
            }
            }
            .ant-tabs-ink-bar{
                background-color: var(--theme-nav-link-primary);
            }
            .ant-tabs-content{
                .ant-tabs-tabpane{
                    color: var(--theme-bg-reverse);
                }
            }
        }`
        }
      ]
    },
  ]
  return (
    <Layout title='tab'>
    <CompLayout title={"Basic tab"} code={code[0]}>
<BasicTab />
    </CompLayout>
    <CompLayout title={"Slide tab"} code={code[1]}>
<SlideTab />
    </CompLayout>
    </Layout>
  )
}

export default Tab