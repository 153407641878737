import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import Basic from '../Components/Elements/Breadcrumb/Basic'

function Breadcrumb() {
  const code = [
    {
      key:1,
      comp:"Basic Breadcrumb",
      code:[
        {
          tab:'js',
          code:`import { Breadcrumb } from 'antd'
          import React from 'react'
          
          function Basic() {
            return (
              <Breadcrumb
              className='auf-breadcrumb'
              items={[
                {
                  title: <a href="">Home</a>,
                },
                {
                  title: <a href="">Application Center</a>,
                },
                {
                  title: <a href="">Application List</a>,
                },
                {
                  title: 'An Application',
                },
              ]}
            />
            )
          }
          
          export default Basic
          `
        },
        {
          tab:'ts',
          code:`import { Breadcrumb } from 'antd'
          import React from 'react'
          
          const App: React.FC = () => (
            return (
              <Breadcrumb
              className='auf-breadcrumb'
              items={[
                {
                  title: <a href="">Home</a>,
                },
                {
                  title: <a href="">Application Center</a>,
                },
                {
                  title: <a href="">Application List</a>,
                },
                {
                  title: 'An Application',
                },
              ]}
            />
            )
          }
          
          export default Basic
          `
        },
        {
          tab:'scss',
          code:`.auf-breadcrumb{
            .ant-breadcrumb-link{
                color: var(--theme-bg-reverse);
            }
            .ant-breadcrumb-separator{
                color: var(--theme-bg-reverse);
            }
            .ant-breadcrumb-link{
                a{
                    color: var(--theme-bg-reverse);
                    &:hover{
                        background-color: var(--theme-nav-link-primary);
                    }
                }
            }
        }`
        }
      ]
    }
  ]
  return (
    <Layout title='slider'>
    <CompLayout title={"Basic Breadcrumb"} code={code[0]}>
    <Basic />
    </CompLayout>
    </Layout>
  )
}

export default Breadcrumb