import { Button, Modal } from 'antd';
import React, { useState } from 'react'

function Basic() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    const footer = (
      <>
        <Button onClick={handleCancel} className='auf-button' >Cancel</Button>
        <Button onClick={handleOk} className='auf-button ant-btn-primary'>Ok</Button>
      </>
    )
  return (
    <>
      <Button className='auf-button' type="primary" onClick={showModal}>
        Open Modal
      </Button>
      <Modal className='auf-modal'  title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    </>
  )
}

export default Basic