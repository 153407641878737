import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import Basic from '../Components/Elements/Form/Basic'

function FormField() {
  const code = [
    {
      key:1,
      comp:"Basic form",
      code:[
        {
          tab:'js',
          code:`import React from 'react'
          import { Button, Checkbox, Form, Input } from 'antd';
          function App() {
              const onFinish = (values) => {
                  console.log('Success:', values);
                };
                const onFinishFailed = (errorInfo) => {
                  console.log('Failed:', errorInfo);
                };
            return (
              <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className='auf-form'
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
          
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
          
              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
          
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import React from 'react'
          import { Button, Checkbox, Form, Input } from 'antd';
          const App: React.FC = () => (
            const onFinish = (values: any) => {
              console.log('Success:', values);
            };
            const onFinishFailed = (errorInfo: any) => {
              console.log('Failed:', errorInfo);
            };
            
            return (
              <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className='auf-form'
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
          
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
          
              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
          
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
            )
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-form{
            .ant-form-item-required{
                color: var(--theme-bg-reverse) !important;
            }
            .ant-input-affix-wrapper{
                background-color: var(--theme-bg-primary);
                border-color: $gray;
                &:hover{
                    border-color: var(--theme-nav-link-primary);
                }
                &-focused{
                    border-color: var(--theme-nav-link-primary);
                    box-shadow: none;
                }
                .ant-input{
                    // color: var(--theme-bg-reverse) !important;
                    box-shadow: none;
                }
            }
            input{
                background-color: var(--theme-bg-primary) !important;
                border-color: $gray;
                color: var(--theme-bg-reverse);
                &:hover{
                    border-color: var(--theme-nav-link-primary);
                }
                &:focus{
                    border-color: var(--theme-nav-link-primary);
                    box-shadow: none;
                }
            }
            .ant-input-suffix{
                .anticon{
                    color: var(--theme-bg-reverse);
                    &:hover{
                        color:var(--theme-nav-link-primary);
                    }
                }
            }
        }`
        }
      ]
    }
  ]
  return (
    <Layout title='form'>
    <CompLayout title={"basic form field"} code={code[0]}>
<Basic />
    </CompLayout>
    </Layout>
  )
}

export default FormField