import React from 'react'
import { Input } from 'antd';
const { Search } = Input;
function SearchWithLoading() {
  return (
    <div style={{
        display:'flex',
        flexDirection:'column',
        gap:'10px'
    }}>
         <Search className='auf-search' placeholder="input search loading default" loading />
    <Search className='auf-search' placeholder="input search loading with enterButton" loading enterButton />
    <Search className='auf-search' placeholder="input search text" enterButton="Search" size="large" loading />
    </div>
  )
}

export default SearchWithLoading