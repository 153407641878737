import React from 'react'
import Layout from '../Components/Layout'
import CompLayout from '../Components/CompLayout'
import BasicTable from '../Components/Elements/Table/BasicTable'
import ScollableTable from './../Components/Elements/Table/ScollableTable';

function Table() {
  const code = [
    {
      key:1,
      comp:"standard table",
      code:[
        {
          tab:'js',
          code:`import { Table } from 'antd';
          import React from 'react'
          
          function App() {
              const columns = [
                  {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    render: (text) => <a>{text}</a>,
                  },
                  {
                    title: 'Age',
                    dataIndex: 'age',
                    key: 'age',
                  },
                  {
                    title: 'Address',
                    dataIndex: 'address',
                    key: 'address',
                  },
                  {
                    title: 'Action',
                    key: 'action',
                    render: (_, record) => (
                      <div>
                        <a>Invite {record.name}</a>
                        <a>Delete</a>
                      </div>
                    ),
                  },
                ];
                const data = [
                  {
                    key: '1',
                    name: 'John Brown',
                    age: 32,
                    address: 'New York No. 1 Lake Park',
                  },
                  {
                    key: '2',
                    name: 'Jim Green',
                    age: 42,
                    address: 'London No. 1 Lake Park',
                  },
                  {
                    key: '3',
                    name: 'Joe Black',
                    age: 32,
                    address: 'Sydney No. 1 Lake Park',
                  },
                ];
            return (
              <Table className='auf-table' columns={columns} dataSource={data} />
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import { Table } from 'antd';
          import React from 'react'
          import type { TableProps } from 'antd';

          const App: React.FC = () => (
            const columns: TableProps<DataType>['columns'] = [
                  {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    render: (text) => <a>{text}</a>,
                  },
                  {
                    title: 'Age',
                    dataIndex: 'age',
                    key: 'age',
                  },
                  {
                    title: 'Address',
                    dataIndex: 'address',
                    key: 'address',
                  },
                  {
                    title: 'Action',
                    key: 'action',
                    render: (_, record) => (
                      <div>
                        <a>Invite {record.name}</a>
                        <a>Delete</a>
                      </div>
                    ),
                  },
                ];
                const data: DataType[] = [
                  {
                    key: '1',
                    name: 'John Brown',
                    age: 32,
                    address: 'New York No. 1 Lake Park',
                  },
                  {
                    key: '2',
                    name: 'Jim Green',
                    age: 42,
                    address: 'London No. 1 Lake Park',
                  },
                  {
                    key: '3',
                    name: 'Joe Black',
                    age: 32,
                    address: 'Sydney No. 1 Lake Park',
                  },
                ];
            return (
              <Table className='auf-table' columns={columns} dataSource={data} />
            )
          }
          
          export default App
          `
        },
        {
          tab:'scss',
          code:`.auf-table{
            .ant-table{
                background-color: var(--theme-bg-primary);
                color: var(--theme-bg-reverse);
            }
            .ant-table-cell{
                background-color: var(--theme-bg-primary);
                border-color: var(--theme-bg-reverse) !important;
                color: var(--theme-bg-reverse);
                a{
                    color: var(--theme-nav-link-primary);
                }
                &.ant-table-cell-row-hover{
                    background-color: $gray !important;
                }
            }
            .ant-table-thead{
                tr{
                    th{
                        background-color: var(--theme-bg-primary);
                color: var(--theme-bg-reverse);
        
                &::before{
                    background-color: var(--theme-bg-reverse) !important;
                }
                    }
                }
            }
            .ant-table-tbody{
                tr{
                    td{
                        
                    }
                }
            }
            .anticon.anticon-left,.anticon-right{
                color: $gray;
            }
            .ant-pagination-item{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .ant-pagination-item.ant-pagination-item-active{
                display: flex;
                align-items: center;
                justify-content: center;
                border-color: var(--theme-nav-link-primary);
                a{
                    color: var(--theme-nav-link-primary);
                }
            }
        }`
        }
      ]
    },
    {
      key:2,
      comp:"scrollable table",
      code:[
        {
          tab:'js',
          code:`import React from 'react'
          import { Table } from 'antd';
          
          function App() {
              const columns = [
                  {
                    title: 'Name',
                    dataIndex: 'name',
                    width: 150,
                  },
                  {
                    title: 'Age',
                    dataIndex: 'age',
                    width: 150,
                  },
                  {
                    title: 'Address',
                    dataIndex: 'address',
                  },
                ];
                const data = [];
                for (let i = 0; i < 100; i++) {
                  data.push({
                    key: i,
                    name: Edward King ,i,
                    age: 32,
                    address: London, Park Lane no. ,i,
                  });
                }
            return (
              <div style={{
                width:'1000px'
              }}>
          <Table
          className='auf-table'
              columns={columns}
              dataSource={data}
              pagination={{
                pageSize: 50,
              }}
              scroll={{
                y: 240,
              }}
            />
          </div>
          
            )
          }
          
          export default App
          `
        },
        {
          tab:'ts',
          code:`import React from 'react';
          import { Table } from 'antd';
          import type { TableColumnsType } from 'antd';
          
          interface DataType {
            key: React.Key;
            name: string;
            age: number;
            address: string;
          }
          
          const columns: TableColumnsType<DataType> = [
            {
              title: 'Name',
              dataIndex: 'name',
              width: 150,
            },
            {
              title: 'Age',
              dataIndex: 'age',
              width: 150,
            },
            {
              title: 'Address',
              dataIndex: 'address',
            },
          ];
          
          const data: DataType[] = [];
          for (let i = 0; i < 100; i++) {
            data.push({
              key: i,
              name: Edward King ,i,
              age: 32,
              address: London, Park Lane no. ,i,
            });
          }
          
          const App: React.FC = () => (
            <Table columns={columns} dataSource={data} pagination={{ pageSize: 50 }} scroll={{ y: 240 }} />
          );
          
          export default App;
          `
        },
        {
          tab:'scss',
          code:`.auf-table{
            .ant-table{
                background-color: var(--theme-bg-primary);
                color: var(--theme-bg-reverse);
            }
            .ant-table-cell{
                background-color: var(--theme-bg-primary);
                border-color: var(--theme-bg-reverse) !important;
                color: var(--theme-bg-reverse);
                a{
                    color: var(--theme-nav-link-primary);
                }
                &.ant-table-cell-row-hover{
                    background-color: $gray !important;
                }
            }
            .ant-table-thead{
                tr{
                    th{
                        background-color: var(--theme-bg-primary);
                color: var(--theme-bg-reverse);
        
                &::before{
                    background-color: var(--theme-bg-reverse) !important;
                }
                    }
                }
            }
            .ant-table-tbody{
                tr{
                    td{
                        
                    }
                }
            }
            .anticon.anticon-left,.anticon-right{
                color: $gray;
            }
            .ant-pagination-item{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .ant-pagination-item.ant-pagination-item-active{
                display: flex;
                align-items: center;
                justify-content: center;
                border-color: var(--theme-nav-link-primary);
                a{
                    color: var(--theme-nav-link-primary);
                }
            }
        }`
        }
      ]
    },
  ]
  return (
    <Layout title='table'>
    <CompLayout title={"standard Table"} code={code[0]}>
<BasicTable />
    </CompLayout>
    <CompLayout title={"Scrollable Table"} code={code[1]}>
<ScollableTable />
    </CompLayout>
    </Layout>
  )
}

export default Table